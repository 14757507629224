import { v4 as uuidv4 } from "uuid";
import { UserService } from "../../services/user_service";
const userService = new UserService();

export const updateUser = async (user, userData) => {
  const userObject = {
    id: userData.id,
    firebaseAuthId: user.uid,
    firstName: userData.firstName,
    lastName: userData.lastName,
    fullName: userData.fullName,
    companyName: userData.companyName,
    companyTaxNumber: userData.companyTaxNumber,
    phoneNumber: userData.phoneNumber,
    emailAddress: user.email,
    addresses: [],
    isEmailVerified: false,
    flag: false,
    /*address: {
      street: userData[0].street,
      houseNumber: userData[0].houseNumber,
      zipcode: userData[0].zipcode,
      city: userData[0].city,
      additionalInfos: userData[0].additionalInfos,
    },
    bankInfo: {
      bankName: userData[0].bankName,
      iban: userData[0].iban,
      bic: userData[0].bic,
      taxNumber: userData[0].taxNumber,
    },*/
    createdAt: new Date().toISOString(),
    createdBy: userData.createdBy,
    updatedAt: new Date().toISOString(),
    updatedBy: userData.id,
    newUser: userData.newUser,
  };
  const response = await userService.putUpdateUser(userObject);
  return response;
};

export const createUser = async (user, userData, token) => {
  const uuid = uuidv4();
  const userObject = {
    id: uuid,
    firebaseAuthId: user.uid,
    firstName: userData.firstName,
    lastName: userData.lastName,
    fullName: userData.fullName,
    companyName: userData.companyName,
    companyTaxNumber: userData.companyTaxNumber,
    phoneNumber: userData.phoneNumber,
    emailAddress: user.email,
    address: {
      street: userData[0].street,
      houseNumber: userData[0].houseNumber,
      zipcode: userData[0].zipcode,
      city: userData[0].city,
      additionalInfos: userData[0].additionalInfos,
    },
    bankInfo: {
      bankName: userData[0].bankName,
      iban: userData[0].iban,
      bic: userData[0].bic,
      taxNumber: userData[0].taxNumber,
    },
    createdAt: new Date().toISOString(),
    createdBy: uuid,
    updatedAt: new Date().toISOString(),
    updatedBy: uuid,
    newUser: true,
  };
  const response = await userService.postCreateUser(userObject);
  return response;
};
