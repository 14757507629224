export const translateOrderState = (state) => {
  switch (state) {
    case "ORDER_IN_PROGRESS_UNPAYED":
      return "ausstehend";
    case "ORDER_IN_PROGRESS":
      return "In Bearbeitung";
    case "ORDER_READY_TO_PICK_UP":
      return "Bereit zur Abholung";
    case "ORDER_ON_DELIVERY":
      return "In Zustellung";
    case "ORDER_DELIVERD_TO_CUSTOMER":
      return "Zugestellt";
    case "ORDER_CANT_DELIVERD_TO_CUSTOMER":
      return "Zustellung nicht möglich";
    case "ORDER_REQUEST_CANCELLATION_BY_CUSTOMER":
      return "Stornierungsanfrage durch Kunden";
    case "ORDER_REQUEST_CANCELLATION_BY_CUSTOMER_IS_ACCEPTED_BY_SHOP":
      return "Stornierung durch Kunden vom Shop akzeptiert";
    case "ORDER_REQUEST_CANCELLATION_BY_CUSTOMER_IS_ACCEPTED_BY_SUPPORT":
      return "Stornierung durch Kunden vom Support akzeptiert";
    case "ORDER_CANCELLED_BY_SHOP":
      return "Storniert durch Shop";
    case "ORDER_CANCELLED_BY_SUPPORT":
      return "Storniert durch Support";
    case "ORDER_SUPPORT_CONTACTING_BY_CUSTOMER":
      return "Supportanfrage durch Kunden";
    case "ORDER_SUPPORT_CONTACTING_BY_SHOP":
      return "Supportanfrage durch Shop";
    case "ORDER_SUPPORT_IN_PROGRESS":
      return "Support in Bearbeitung";
    case "ORDER_SUPPORT_WAITING_FOR_CUSTOMER":
      return "Warten auf Kundenantwort";
    case "ORDER_SUPPORT_WAITING_FOR_SHOP":
      return "Warten auf Shop-Antwort";
    case "ORDER_SUPPORT_CLOSED_BY_CUSTOMER":
      return "Ticket durch Kunden geschlossen";
    case "ORDER_SUPPORT_CLOSED_BY_SHOP":
      return "Ticket durch Shop geschlossen";
    case "ORDER_SUPPORT_CLOSED_BY_SUPPORT":
      return "Ticket durch Support geschlossen";
    case "ORDER_UNKNOWN_PROBLEM":
      return "Unbekanntes Problem";
    default:
      return state;
  }
};
