import React, { useState, useEffect } from "react";
import Switch from "../Switch/Switch";
import TextInputField from "../TextInputField/TextInputField";
import CustomButton from "../Button/Button";
import "./WeekDayOpeningTime.css";

const addOneHour = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const newHours = (hours + 1) % 24;
  return `${String(newHours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

const isTimeBefore = (time1, time2) => {
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);
  return hours1 < hours2 || (hours1 === hours2 && minutes1 < minutes2);
};

export default function WeekDayOpeningTime({
  day,
  defaultOpen,
  openingTimes,
  onChange,
}) {
  const [open, setOpen] = useState(defaultOpen);
  const [times, setTimes] = useState(openingTimes);
  // eslint-disable-next-line
  const [error, setError] = useState("");

  useEffect(() => {
    setTimes(openingTimes);
    setOpen(defaultOpen);
  }, [openingTimes, defaultOpen]);

  const handleOnClick = () => {
    const newOpen = !open;
    setOpen(newOpen);
    if (newOpen) {
      if (times.length === 0) {
        const initialTimes = [{ start: "09:00", end: "17:00" }];
        setTimes(initialTimes);
        onChange(initialTimes);
      } else {
        onChange(times);
      }
    } else {
      setTimes([]);
      onChange([]);
    }
  };

  const handleStartTimeChange = (index, newValue) => {
    const newTimes = [...times];
    newTimes[index].start = newValue;
    setTimes(newTimes);
    validateTimes(newTimes);
    onChange(newTimes);
  };

  const handleEndTimeChange = (index, newValue) => {
    const newTimes = [...times];
    newTimes[index].end = newValue;
    setTimes(newTimes);
    validateTimes(newTimes);
    onChange(newTimes);
  };

  const validateTimes = (newTimes) => {
    let isValid = true;
    for (let i = 0; i < newTimes.length - 1; i++) {
      if (!isTimeBefore(newTimes[i].end, newTimes[i + 1].start)) {
        isValid = false;
        break;
      }
    }
    setError(isValid ? "" : "Die Zeiten dürfen sich nicht überschneiden.");
    return isValid;
  };

  const handleToggleOpeningTime = () => {
    if (times.length > 1) {
      const newTimes = times.slice(0, -1);
      setTimes(newTimes);
      setError("");
      onChange(newTimes);
    } else {
      const lastEnd = times[times.length - 1].end;
      const newStart = addOneHour(lastEnd);
      const newEnd = addOneHour(newStart);
      const newTimes = [...times, { start: newStart, end: newEnd }];
      if (validateTimes(newTimes)) {
        setTimes(newTimes);
        onChange(newTimes);
      }
    }
  };

  const dayTranslations = {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
  };

  const buttonLabel =
    times.length > 1 ? "Öffnungszeit entfernen" : "Öffnungszeiten hinzufügen";

  return (
    <div className="weekday-openingtime-container">
      <div className="weekday-top-container">
        <div>
          <p>{dayTranslations[day] || day}</p>
        </div>
      </div>
      <div className="weekday-bottom-container">
        <div className="switch-container">
          <Switch onClick={handleOnClick} value={open} />
        </div>
        {open && (
          <>
            <div className="time-list">
              {times.map((time, index) => (
                <div key={index} className="time-item">
                  <div className={`time-container time-container-${index}`}>
                    <TextInputField
                      inputType="time"
                      value={time.start}
                      onChange={(e) =>
                        handleStartTimeChange(index, e.target.value)
                      }
                      error={
                        index > 0 &&
                        !isTimeBefore(times[index - 1].end, time.start)
                      }
                      supportingText={
                        index > 0 &&
                        !isTimeBefore(times[index - 1].end, time.start)
                          ? "Die Zeiten dürfen sich nicht überschneiden."
                          : ""
                      }
                    />
                    <span>bis</span>
                    <TextInputField
                      inputType="time"
                      value={time.end}
                      onChange={(e) =>
                        handleEndTimeChange(index, e.target.value)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            <CustomButton
              type="primary"
              variant="text"
              label={buttonLabel}
              onClick={handleToggleOpeningTime}
            />
          </>
        )}
      </div>
    </div>
  );
}
