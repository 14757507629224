import { APIClient } from "./api_service";

const api = new APIClient();

//USER

export async function getUserByEmailEndpoint(email) {
  try {
    const response = await api.get("/user", email);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getUserByIdEndpoint(id) {
  try {
    const response = await api.get(`/user/get/id?id=${id}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getUserByFirebaseIdEndpoint(firebaseId) {
  try {
    const response = await api.get(
      `/user/get/firebaseId?firebaseAuthId=${firebaseId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getAllUserEndpoint() {
  try {
    const response = await api.get("/user/get/all");
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function postCreateUserEndpoint(user) {
  try {
    const response = await api.post("/user/create", user);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function putUpdateUserEndpoint(user) {
  try {
    const response = await api.put("/user/update", user);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

//Shop

export async function postCreateShopEndpoint(shopData) {
  try {
    const response = await api.post(`/shop/create`, shopData);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Erstellen des Shops:", error);
    throw error;
  }
}

export async function getUserShopsEndpoint() {
  try {
    const response = await api.get(`/shop/user/shops`);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Abrufen des Shops:", error);
    throw error;
  }
}

export async function putUpdateShopEndpoint(shopData) {
  try {
    const response = await api.put(`/shop/update`, shopData);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren des Shops:", error);
    throw error;
  }
}

// Shop Image

export async function putImageShopEndpoint(shopUUID, imageType, formData) {
  try {
    const response = await api.post(
      `/image/uploadImage?shopUUID=${shopUUID}&type=${imageType}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Fehler beim Hochladen des Bildes", error);
    throw error;
  }
}

export async function deleteImageShopEndpoint(/*shopUUID, */ imagePath) {
  try {
    const response = await api.delete(
      `/image/deleteImage?imagePath=${imagePath}`
    );
    return response;
  } catch (error) {
    console.error("Fehler beim Löschen des Bildes", error);
    throw error;
  }
}

export async function replaceImageShopEndpoint(shopUUID, imagePath, formData) {
  try {
    const response = await api.put(
      `/image/replaceImage?shopUUID=${shopUUID}&imagePath=${imagePath}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Fehler beim Ersetzen des Bildes", error);
    throw error;
  }
}

//Produkt

export async function getProductByIdEndpoint(id) {
  try {
    const response = await api.get(`/product/get/id`, id);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

export async function getProgetAllProductsByEANductByIdEndpoint(ean) {
  try {
    const response = await api.get(`/product/get/ean`, ean);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

export async function getProductByShopIdAndCatEndpoint(id, category) {
  try {
    let url = `/product/get/shop?id=${id}`;
    if (category) {
      url += `&category=${category}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

export async function getProductsByCategoryEndpoint(category) {
  try {
    const response = await api.get(`/product/get/category`, category);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

export async function getAllProductsEndpoint() {
  try {
    const response = await api.get(`/product/get/all`);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

export async function postCreateProductEndpoint(product) {
  try {
    const response = await api.post(`/product/create`, product);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

export async function putUpdateProductEndpoint(product) {
  try {
    const response = await api.put(`/product/update`, product);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

export async function deleteProductEndpoint(id) {
  try {
    const response = await api.delete(`/product/delete`, id);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Produkte:", error);
    throw error;
  }
}

//Order

export async function getOrderByIdEndpoint(id) {
  try {
    const response = await api.get(`/order/id`, id);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

export async function getAllOrdersEndpoint() {
  try {
    const response = await api.get(`/order/get/all`);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

export async function getAllOrdersFromUserEndpoint(id) {
  try {
    const response = await api.get(`/order/get/all/user`, id);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

export async function getAllOrdersByShopIdEndpoint(id, isCanceled) {
  try {
    let url = `/order/get/all/shop?id=${id}`;
    if (isCanceled) {
      url += `&isCanceled=${isCanceled}`;
    }
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

export async function postCreateOrderEndpoint(order) {
  try {
    const response = await api.post(`/order/create`, order);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

export async function putSetOrdersAsCanceledEndpoint(id, cancelReason) {
  try {
    const response = await api.put(`/order/cancel`, id, cancelReason);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

export async function putUpdateOrderEndpoint(order) {
  try {
    const response = await api.put(`/order/update`, order);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

export async function deleteOrderEndpoint(name) {
  try {
    const response = await api.delete(`/order/delete`, name);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Bestellung:", error);
    throw error;
  }
}

//Category

export async function getCategoryByIdEndpoint(id) {
  try {
    const response = await api.get(`/category`, id);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Kategorien:", error);
    throw error;
  }
}

export async function getCategoryByNameEndpoint(name) {
  try {
    const response = await api.get(`/category/get`, name);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Kategorien:", error);
    throw error;
  }
}

export async function getCategoryByTagEndpoint(tags) {
  try {
    const response = await api.get(`/category/tags`, tags);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Kategorien:", error);
    throw error;
  }
}

export async function getAllCategoriesEndpoint() {
  try {
    const response = await api.get(`/category/get/all`);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Kategorien:", error);
    throw error;
  }
}

export async function postCreateCategoryEndpoint(category) {
  try {
    const response = await api.post(`/category/create`, category);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Kategorien:", error);
    throw error;
  }
}

export async function putUpdateCategoryEndpoint(category) {
  try {
    const response = await api.put(`/category/update`, category);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Kategorien:", error);
    throw error;
  }
}

export async function deleteCategoryEndpoint(id) {
  try {
    const response = await api.delete(`/category/delete`, id);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Aktualisieren der Kategorien:", error);
    throw error;
  }
}

// Cookie
export async function postCreateCookieEndpoint(cookie) {
  try {
    const response = await api.post(`/auth/login`, cookie, {
      withCredentials: true,
    });
    const cookies = document.cookie;
    console.log("Cookies im Browser:", cookies);
    console.log("Response from postCreateCookieEndpoint", response);
    return response.data;
  } catch (error) {
    console.error("Fehler bei der Erstellung der Cookies:", error);
    throw error;
  }
}

export async function postDeleteCookieEndpoint() {
  try {
    const response = await api.post(`/auth/logout`);
    return response.data;
  } catch (error) {
    console.error("Fehler beim Löschen der Cookies:", error);
    throw error;
  }
}
