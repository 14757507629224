import React, { useState, useEffect } from "react";
import TextInputField from "../../components/TextInputField/TextInputField";
import Checkbox from "../../components/Checkbox/Checkbox";
import WeekDayOpeningTime from "../../components/WeekDayOpeningTime/WeekDayOpeningTime";
import "../ShopManagement/ShopManagement.css";
import LocaloraNewLogo from "../../assets/Images/BannerShop.svg";
import UploadIcon from "../../assets/Images/uploadImage.svg";
import TextArea from "../../components/TextArea/TextArea";
import CustomButton from "../../components/Button/Button";
import { ShopService } from "../../services/shop_service";
const shopService = new ShopService();

export default function ShopManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    address: {
      street: "",
      houseNumber: "",
      postalCode: "",
      city: "",
      addition: "",
      state: "",
      country: "",
      longitude: "",
      latitude: "",
      flagDelivery: true,
      flagInvoice: true,
      flagActive: true,
      createdBy: "",
      createdAt: "",
      updatedBy: "",
      updatedAt: "",
    },
    maxDeliveryDistance: 0,
    deliveryMethods: [],
    openingTimes: [
      { day: "monday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "tuesday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "wednesday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "thursday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "friday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "saturday", times: [] },
      { day: "sunday", times: [] },
    ],
    description: "",
    contact: {
      phone: "",
      email: "",
    },
    shopImageURL: "",
    stageImageURL: "",
    itemImageUrls: {
      logo: "",
      thumbnail: "",
    },
  });

  useEffect(() => {
    const loadUserShops = async () => {
      setIsLoading(true);
      try {
        const shops = await shopService.getUserShops();

        if (shops && shops.length > 0) {
          const shop = shops[0];
          const newFormData = {
            id: shop.id,
            shopDetailsId: shop.shopDetailsId,
            name: shop.name,
            address: {
              street: shop.address?.street || "",
              houseNumber: shop.address?.houseNumber || "",
              postalCode: shop.address?.postalCode || "",
              city: shop.address?.city || "",
              addition: shop.address?.addition || "",
              state: shop.address?.state || "",
              country: shop.adress?.country || "",
              longitude: shop.address?.longitude || "",
              latitude: shop.address?.latitude || "",
              flagDelivery: shop.address?.flagDelivery || true,
              flagInvoice: shop.address?.flagInvoice || true,
              flagActive: shop.address?.flagActive || true,
              createdBy: shop.address?.createdBy,
              createdAt: shop.address?.createdAt,
              updatedBy: shop.address?.updateBy,
              updatedAt: shop.address?.updatedAt,
            },
            maxDeliveryDistance: shop.maxDeliveryDistance || 0,
            deliveryMethods: shop.deliveryMethods || [],
            openingTimes: shop.openingTimes || [
              { day: "monday", times: [{ start: "09:00", end: "17:00" }] },
              { day: "tuesday", times: [{ start: "09:00", end: "17:00" }] },
              { day: "wednesday", times: [{ start: "09:00", end: "17:00" }] },
              { day: "thursday", times: [{ start: "09:00", end: "17:00" }] },
              { day: "friday", times: [{ start: "09:00", end: "17:00" }] },
              { day: "saturday", times: [] },
              { day: "sunday", times: [] },
            ],
            description: shop.description || "",
            contact: {
              phone: shop.phoneNumber || "",
              email: shop.emailAddress || "",
            },
            websiteUrl: shop.websiteUrl || "",
            isActive: shop.isActive || false,
            taxNumber: shop.taxNumber,
            lastInvoiceNo: shop.lastInvoiceNo,
            lastOrderNo: shop.lastOrderNo,
            productLimit: shop.productLimit,
            shopImageURL: shop.shopImageURL || LocaloraNewLogo,
            stageImageURL: shop.stageImageURL || LocaloraNewLogo,
            userList: shop.userList,
            itemImageUrls: {
              logo: shop.itemImageUrls?.logo || LocaloraNewLogo,
              thumbnail: shop.itemImageUrls?.thumbnail || LocaloraNewLogo,
            },
          };
          setFormData(newFormData);
        }
      } catch (error) {
        console.error("Fehler beim Laden der Nutzershops:", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadUserShops();
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    const [field, subfield] = name.split(".");
    setFormData((prevState) => {
      if (subfield) {
        return {
          ...prevState,
          [field]: { ...prevState[field], [subfield]: value },
        };
      }
      return { ...prevState, [name]: value };
    });
  };

  const handleOpeningTimeChange = (day, times) => {
    setFormData((prevState) => ({
      ...prevState,
      openingTimes: prevState.openingTimes.map((openingTime) =>
        openingTime.day === day ? { ...openingTime, times } : openingTime
      ),
    }));
  };

  const handleCheckboxChange = (option) => {
    setFormData((prevState) => {
      const deliveryMethods = prevState.deliveryMethods.includes(option)
        ? prevState.deliveryMethods.filter((opt) => opt !== option)
        : [...prevState.deliveryMethods, option];
      return { ...prevState, deliveryMethods };
    });
  };

  const handleSave = async () => {
    try {
      await shopService.putUpdateShop(formData);
      alert("Shop-Daten wurden erfolgreich aktualisiert");
      window.location.reload();
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Shop-Daten:", error);
      alert("Fehler beim Aktualisieren der Shop-Daten");
    }
  };

  const deleteImage = async (imageType, type) => {
    try {
      const response = await shopService.deleteImageShop(imageType);
      if (response.status === 200) {
        setFormData((prevState) => {
          const updatedImageUrls = { ...prevState.itemImageUrls, [type]: "" };
          return { ...prevState, itemImageUrls: updatedImageUrls };
        });
      } else {
        console.error("Fehler beim Löschen des Bildes:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Löschen des Bildes:", error);
    }
  };

  const handleImageUpload = async (imageType, event) => {
    const file = event.target.files[0];
    const shopUUID = formData.id;
    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await shopService.putImageShop(
        shopUUID,
        imageType,
        formData
      );

      if (response.status === 200) {
        const imageUrl = response.data;
        setFormData((prevState) => ({
          ...prevState,
          itemImageUrls: {
            ...prevState.itemImageUrls,
            [imageType === "shopImageURL" ? "logo" : "thumbnail"]: imageUrl,
          },
        }));
      } else {
        console.error("Fehler beim Hochladen des Bildes:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Hochladen des Bildes:", error);
    }
  };

  return (
    <div className="shop-management-container">
      <div className="shop-management-form-container">
        <h2>{formData.name}</h2>
        <div className="shop-management-form-split">
          <div className="shop-management-left-side">
            <div className="shop-management-adress-container">
              <h4>Adresse</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px 0",
                  width: "300px",
                }}>
                <TextInputField
                  type="text"
                  label="Street"
                  name="address.street"
                  value={formData.address.street}
                  onChange={handleFormChange}
                  loading={isLoading}
                />
                <TextInputField
                  type="text"
                  label="House Number"
                  name="address.houseNumber"
                  value={formData.address.houseNumber}
                  onChange={handleFormChange}
                  loading={isLoading}
                />
                <TextInputField
                  type="text"
                  label="Postal Code"
                  name="address.postalCode"
                  value={formData.address.postalCode}
                  onChange={handleFormChange}
                  loading={isLoading}
                />
                <TextInputField
                  type="text"
                  label="City"
                  name="address.city"
                  value={formData.address.city}
                  onChange={handleFormChange}
                  loading={isLoading}
                />
                <TextInputField
                  type="text"
                  label="Additional Information"
                  name="address.addition"
                  value={formData.address.addition}
                  onChange={handleFormChange}
                  loading={isLoading}
                />
              </div>
              <div className="checkbox-container">
                <h4>Zustellungsmethoden</h4>
                <div className="checkboxes">
                  <Checkbox
                    label="Pickup"
                    checked={formData.deliveryMethods.includes("Pickup")}
                    onChange={() => handleCheckboxChange("Pickup")}
                  />
                  <Checkbox
                    label="Delivery"
                    checked={formData.deliveryMethods.includes("Delivery")}
                    onChange={() => handleCheckboxChange("Delivery")}
                  />
                </div>
              </div>
            </div>

            <div className="shop-management-right-side">
              <h4>Bilder</h4>
              <div className="shop-management-images">
                <div className="image-wrapper">
                  <img
                    src={
                      formData.itemImageUrls?.logo
                        ? formData.itemImageUrls.logo
                        : LocaloraNewLogo
                    }
                    alt="Shop Icon"
                    className="full-width-image"
                  />
                  <div className="upload-overlay">
                    <label htmlFor="shopImageUpload">
                      <img
                        src={UploadIcon}
                        alt="Upload Icon"
                        className="upload-icon"
                      />
                      <input
                        type="file"
                        id="shopImageUpload"
                        accept="image/*"
                        onChange={(e) => handleImageUpload("shopImageURL", e)}
                      />
                    </label>
                    {formData.itemImageUrls.logo && (
                      <button
                        onClick={() =>
                          deleteImage(formData.itemImageUrls.logo, "logo")
                        }
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        X
                      </button>
                    )}
                  </div>
                </div>
                <div className="image-wrapper">
                  <img
                    src={
                      formData.itemImageUrls?.thumbnail
                        ? formData.itemImageUrls.thumbnail
                        : LocaloraNewLogo
                    }
                    alt="Stage Icon"
                    className="full-width-image"
                  />
                  <div className="upload-overlay">
                    <label htmlFor="stageImageUpload">
                      <img
                        src={UploadIcon}
                        alt="Upload Icon"
                        className="upload-icon"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <input
                        type="file"
                        id="stageImageUpload"
                        accept="image/*"
                        onChange={(e) => handleImageUpload("stageImageURL", e)}
                      />
                    </label>
                    {formData.itemImageUrls.thumbnail && (
                      <button
                        onClick={() =>
                          deleteImage(
                            formData.itemImageUrls.thumbnail,
                            "thumbnail"
                          )
                        }
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        X
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="shop-management-description-container">
                <h4>Beschreibung</h4>
                <TextArea
                  label="Beschreibung"
                  customClass="custom-input-size"
                  onChange={handleFormChange}
                  rows={13}
                  value={formData.description}
                  name="description"
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="input-container">
              <h4>Öffnungszeiten</h4>
              <div className="weekday-container">
                {formData.openingTimes.map(({ day, times }) => (
                  <div key={day}>
                    <WeekDayOpeningTime
                      day={day}
                      defaultOpen={times.length > 0}
                      openingTimes={times}
                      onChange={(newTimes) =>
                        handleOpeningTimeChange(day, newTimes)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <CustomButton
          label="Speichern"
          type="primary"
          variant="filled"
          onClick={handleSave}
        />
      </div>
    </div>
  );
}
