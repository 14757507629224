import React from "react";
import "./Stepper.css";

const Stepper = ({ currentStep, totalSteps }) => {
  return (
    <div className="stepper">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`step ${index === currentStep ? "active" : ""}`}></div>
      ))}
    </div>
  );
};

export default Stepper;
