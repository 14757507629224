import React from "react";
import "./OrderTableSkeleton.css";

const OrderTableSkeletonLoader = () => {
  return (
    <table className="order-skeleton-table">
      <thead>
        <tr>
          <th>Bestellnummer</th>
          <th>Datum</th>
          <th>Kunde</th>
          <th>Adresse</th>
          <th>Bezahlungsstatus</th>
          <th>Liefermethode</th>
          <th>Lieferstatus</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr key={index}>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OrderTableSkeletonLoader;
