import React, { useState } from "react";
import { firebaseApp } from "../../firebase/firebase";
import "firebase/auth";
import "./Register.css";
import LocaLoraLogo from "../../assets/Images/LocaLoraLogo.svg";
import { v4 as uuidv4 } from "uuid";
import { UserService } from "../../services/user_service";

export default function RegisterForm({ handleToggle }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const userService = new UserService();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const checkIfRight = (e) => {
    setPasswordRepeat(e.target.value);
  };

  const handleEmailRegister = async (event) => {
    event.preventDefault();
    const auth = firebaseApp.auth();

    if (password !== passwordRepeat) {
      alert("Passwörter stimmen nicht überein");
      return;
    }

    // Start loading
    setIsLoading(true);

    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const user = userCredential.user;

      await user.sendEmailVerification();
      alert("Verifizierungsmail wurde gesendet");

      const uuid = uuidv4();
      const userObject = {
        id: uuid,
        firebaseAuthId: user.uid,
        firstName: "firstName",
        lastName: "lastName",
        fullName: "fullName",
        companyName: "companyName",
        companyTaxNumber: "companyTaxNumber",
        phoneNumber: 123,
        emailAddress: user.email,
        addresses: [],
        isEmailVerified: false,
        flag: false,
        createdBy: uuid,
        createdAt: new Date().toISOString(),
        updatedBy: uuid,
        updatedAt: new Date().toISOString(),
        newUser: true,
      };

      await user.getIdToken(true);
      await userService.postCreateUser(userObject);

      // Logout
      auth.signOut();
      // Reload site
      window.location.reload();
    } catch (error) {
      // Failure during registration
      const errorMessage = error.message;
      console.error("Registrierungsfehler:", errorMessage);
    } finally {
      // Stop loading
      setIsLoading(false);
    }
  };

  const backToLogin = (e) => {
    handleToggle();
  };

  return (
    <div
      className="register-container"
      style={{
        width: "300px",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div className="logo-image">
        <img src={LocaLoraLogo} alt="Logo"></img>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="register-form" onSubmit={handleEmailRegister}>
          <label htmlFor="email"></label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            style={{ textAlign: "center", height: "30px" }}
            required
          />
          <label htmlFor="password"></label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Password"
            style={{ textAlign: "center", height: "30px" }}
            onChange={handlePasswordChange}
            required
          />
          <input
            type="password"
            id="passwordRepeat"
            value={passwordRepeat}
            placeholder="Again password"
            style={{ textAlign: "center", height: "30px" }}
            onChange={checkIfRight}
            required
          />
          <div className="buttonHolder">
            <button id="loginBackButton" onClick={backToLogin} type="button">
              Back to Login
            </button>
            <button id="registerButton" type="submit">
              Create account
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
