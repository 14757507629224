import { useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./ThemeModeToggle.css";

const ThemeModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme
      ? storedTheme === "dark"
      : window.matchMedia("(prefers-color-scheme: dark)").matches;
  });

  const applyTheme = (theme) => {
    const root = document.documentElement;
    if (theme === "dark") {
      root.classList.add("theme-dark");
      root.classList.remove("theme-light");
    } else {
      root.classList.add("theme-light");
      root.classList.remove("theme-dark");
    }
  };

  const toggleDarkMode = (checked) => {
    const theme = checked ? "dark" : "light";
    applyTheme(theme);
    localStorage.setItem("theme", theme);
    setIsDarkMode(checked);
  };

  return (
    <Toggle
      checked={isDarkMode}
      onChange={({ target }) => toggleDarkMode(target.checked)}
      icons={{ checked: "🌙", unchecked: "🔆" }}
      aria-label="Dark mode toggle"
    />
  );
};

export default ThemeModeToggle;
