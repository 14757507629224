import React, { useState } from "react";
import Papa from "papaparse";
import "./CsvFileInput.css"; // Stelle sicher, dass du die CSS-Datei importierst
import CustomButton from "../../components/Button/Button";
import { MdOutlineFileUpload } from "react-icons/md";

const CsvFileInput = ({ onFileLoad }) => {
  const fileInputRef = React.useRef(null);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFileName(file.name);
      Papa.parse(file, {
        complete: (result) => {
          onFileLoad(result.data);
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div className="fileinput-container">
      <input
        type="file"
        className="file-input"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <CustomButton
        type="secondary"
        variant="outlined"
        className="custom-button"
        onClick={handleButtonClick}
        label="Datei auswählen"
        icon={<MdOutlineFileUpload />}></CustomButton>
      <span>{fileName}</span>
    </div>
  );
};

export default CsvFileInput;
