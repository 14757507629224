import React, { useState } from "react";
import TextInputField from "../../../components/TextInputField/TextInputField";
import CustomerInfo from "../../../assets/Images/Onboarding/undraw_credit_card_re_blml.svg";

export default function OnboardingPageFive({ formData, onChange }) {
  const [errors, setErrors] = useState({ iban: "" });

  const handleBankChange = (index, field, value) => {
    const newBankInfos = [...formData.bankInfo];
    newBankInfos[index] = { ...newBankInfos[index], [field]: value };

    if (field === "iban" && value.length === 22) {
      setErrors((prevErrors) => ({ ...prevErrors, iban: "" }));
    }

    onChange("bankInfo", newBankInfos);
  };

  const handleIbanBlur = (e) => {
    const value = e.target.value;
    if (value.length !== 22) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        iban: "IBAN muss genau 22 Zeichen lang sein.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, iban: "" }));
    }
  };

  return (
    <div className="OnboardingPageFive">
      <div className="title-container">
        <img src={CustomerInfo} alt=""></img>
      </div>
      <div>
        <h3>Wie lauten Ihre Bank- und Steuerinformationen?</h3>
      </div>

      <div className="input-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px 0",
            width: "300px",
            margin: "10px 0",
          }}>
          <TextInputField
            type="text"
            label="Bank Name"
            value={formData.bankInfo[0]?.bankName || ""}
            onChange={(e) => handleBankChange(0, "bankName", e.target.value)}
            required
          />
          <TextInputField
            type="text"
            label="IBAN"
            value={formData.bankInfo[0]?.iban || ""}
            error={errors.iban}
            supportingText={errors.iban}
            onChange={(e) => handleBankChange(0, "iban", e.target.value)}
            onBlur={handleIbanBlur}
            required
          />
          <TextInputField
            type="text"
            label="BIC"
            value={formData.bankInfo[0]?.bic || ""}
            onChange={(e) => handleBankChange(0, "bic", e.target.value)}
            required
          />
          <TextInputField
            type="text"
            label="Steuernummer"
            value={formData.bankInfo[0]?.taxNumber || ""}
            onChange={(e) => handleBankChange(0, "taxNumber", e.target.value)}
            required
          />
        </div>
      </div>
    </div>
  );
}
