import React, { useState, useEffect } from "react";
import "./TextArea.css";

export default function TextArea({
  label,
  inputType = "text",
  value,
  error,
  supportingText,
  disabled,
  onChange,
  onBlur,
  required = false,
  name,
  style,
  customClass,
  rows,
  loading,
}) {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, [value]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      setFocused(false);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  if (loading) {
    return (
      <div className={`custom-text-area ${customClass}`} style={style}>
        <div
          className="skeleton-loader"
          style={{ height: "1em", marginBottom: "0.5em" }}></div>
        <div
          className="skeleton-loader"
          style={{ height: "4em", marginBottom: "0.5em" }}></div>
        {supportingText && (
          <div
            className="skeleton-loader"
            style={{ height: "1em", width: "50%" }}></div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`custom-text-area ${focused ? "focused" : ""} ${
        error ? "error" : ""
      } ${customClass}`}
      style={style}>
      {required && <span>*</span>}
      <div>
        <label className={focused || error ? "focused" : ""}>{label}</label>
        <textarea
          type={inputType}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          value={value}
          onChange={onChange}
          rows={rows}
        />
      </div>
      <div>
        {supportingText && (
          <label className="supportingText">{supportingText}</label>
        )}
      </div>
    </div>
  );
}
