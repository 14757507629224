import {
    postCreateCookieEndpoint,
    postDeleteCookieEndpoint
} from "./api_endpoints";

export class CookieService {
    async postCreateCookie(token) {
        try {
            const response = await postCreateCookieEndpoint({ cookie: token }, { withCredentials: true });
            return response;
        } catch (error) {
            console.error("Fehler bei der Erstellung des Cookies.")
        }
    }

    async postDeleteCookie(){
        try {
            const response = await postDeleteCookieEndpoint();
            return response;
        } catch (error) {
            console.error("Fehler beim Löschen des Cookies.");
        }
    }
}