import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { translateOrderState } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import { OrderService } from "../../services/order_service";
import { UserService } from "../../services/user_service";
import { ShopService } from "../../services/shop_service";
import OrderTableSkeletonLoader from "../../components/OrderTableSkeleton/OrderTableSkeleton";
import OrderTable from "../../components/Table/OrderTable/OrderTable";

import "./OrderOverview.css";

export default function OrderOverview() {
  const { shopUUID, setShopUUID } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [customer, setCustomer] = useState();
  const navigate = useNavigate();
  const orderService = new OrderService();
  const userService = new UserService();
  const shopService = new ShopService();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserShops = async () => {
      setLoading(true);
      try {
        const response = await shopService.getUserShops();
        setShopUUID(response[0].id);
      } catch (error) {
        console.error("Fehler beim Laden der Nutzershops:", error);
      }
      setLoading(false);
    };
    loadUserShops();
    // eslint-disable-next-line
  }, [setShopUUID]);

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!shopUUID) return;
      try {
        const orderData = await orderService.getAllOrdersByShopId(shopUUID);
        setOrders(orderData);
      } catch (error) {
        console.error("Error to fetch order data!", error);
      }
    };

    fetchOrderData();
    // eslint-disable-next-line
  }, [shopUUID]);

  useEffect(() => {
    const fetchUserById = async () => {
      try {
        const userId = orders.map((obj) => obj.userId);
        const userData = await userService.getUserById(userId[0]);
        setCustomer(userData.fullName);
      } catch (error) {
        console.error("Error to fetch user data!", error);
      }
    };

    if (orders?.length > 0) {
      fetchUserById();
    }
    // eslint-disable-next-line
  }, [orders]);

  const onOrderClick = async (orderId) => {
    try {
      const order = await orderService.getOrderById({ id: orderId });
      navigate("/orderview", { state: { order, customer } });
    } catch (error) {
      console.error("Fehler beim Abrufen des Produkts:", error);
    }
  };

  const getLastOrderState = (statusHistory) => {
    if (!statusHistory || Object.keys(statusHistory).length === 0) {
      return "-";
    }
    const latestStatus = Object.keys(statusHistory).reduce(
      (latest, current) => {
        return new Date(latest) > new Date(current) ? latest : current;
      }
    );
    return translateOrderState(statusHistory[latestStatus]);
  };

  return (
    <div className="order-overview-container">
      <div className="order-overview-header">
        <h3>Bestellungsübersicht</h3>
      </div>
      {loading ? (
        <OrderTableSkeletonLoader />
      ) : (
        <OrderTable
          orders={orders}
          onOrderClick={onOrderClick}
          getLastOrderState={getLastOrderState}
        />
      )}
    </div>
  );
}
