import React from "react";
import Checkbox from "../../components/Checkbox/Checkbox";
import "../../components/Table/ProductTable/ProductTableContent";

const ProductTableContentCSV = ({
  onProductClick,
  sortedProducts,
  sortTable,
  categoryMapping,
  selectedProducts,
  setSelectedProducts,
}) => {
  const handleCheckboxChange = (productId, checked) => {
    if (checked) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    }
  };

  const isSelected = (id) => selectedProducts.includes(id);

  return (
    <div className="product-table-container">
      <table>
        <thead>
          <tr>
            <th></th>
            <th onClick={() => sortTable("name")}>Name</th>
            <th onClick={() => sortTable("flagActive")}>Status</th>
            <th onClick={() => sortTable("articleNumber")}>Artikelnummer</th>
            <th onClick={() => sortTable("categoryId")}>Kategorie</th>
            <th onClick={() => sortTable("productAmount")}>Bestand</th>
            <th onClick={() => sortTable("measureUnit")}>Einheit</th>
            <th onClick={() => sortTable("basePriceAmount")}>
              Stückzahl pro Produkt
            </th>
            <th onClick={() => sortTable("priceGross")}>Preis Brutto</th>
            <th onClick={() => sortTable("priceNet")}>Preis Netto</th>
          </tr>
        </thead>
        <tbody>
          {sortedProducts.map((product) => (
            <tr key={product.id} onClick={() => onProductClick(product.id)}>
              <td onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checked={isSelected(product.id)}
                  onChange={(checked) =>
                    handleCheckboxChange(product.id, checked)
                  }
                />
              </td>
              <td>{product.name}</td>
              <td>{product.flagActive ? "Aktiv" : "Inaktiv"}</td>
              <td>{product.articleNumber}</td>
              <td>{categoryMapping[product.categoryId]}</td>
              <td>{product.productAmount}</td>
              <td>{product.measureUnit}</td>
              <td>{product.priceGross}</td>
              <td>{product.priceNet}</td>
              <td>{product.tax}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTableContentCSV;
