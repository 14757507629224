export const validateForm = (currentStep, formData) => {
  switch (currentStep) {
    case 0:
      return formData.name.trim() !== "";
    case 1:
      return (
        formData.address[0].street.trim() !== "" &&
        formData.address[0].houseNumber.trim() !== "" &&
        formData.address[0].postalCode.trim() !== "" &&
        formData.address[0].city.trim() !== ""
      );
    case 2:
      return formData.maxDeliveryDistance > 0;
    case 3:
      return true;
    case 4:
      return (
        formData.bankInfo[0].bankName.trim() !== "" &&
        formData.bankInfo[0].iban.trim().length === 22 &&
        formData.bankInfo[0].bic.trim() !== "" &&
        formData.bankInfo[0].taxNumber.trim() !== ""
      );
    default:
      return false;
  }
};

export const handleNextStep = (
  currentStep,
  setCurrentStep,
  navigate,
  formValid,
  formData,
  user,
  userData,
  updateUser,
  createShop
) => {
  if (formValid) {
    if (currentStep < 4) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      navigate(`/onboarding/step${nextStep + 1}`);
    } else {
      createShop(formData, user);
      const updatedUserData = { ...userData, newUser: false };
      try {
        updateUser(user, updatedUserData);
        navigate("/order");
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Benutzers:", error);
      }
    }
  }
};

export const handlePrevStep = (currentStep, setCurrentStep, navigate) => {
  if (currentStep > 0) {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
    navigate(`/onboarding/step${prevStep + 1}`);
  }
};

export const handleFormDataChange = (key, value, setFormData) => {
  setFormData((prevData) => ({
    ...prevData,
    [key]: value,
  }));
};
