import React, { createContext, useState, useEffect } from "react";
import { firebaseApp } from "../firebase/firebase";
import "firebase/auth";
import { useNavigate } from "react-router-dom";
import { CookieService } from "../services/cookie_service";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const cookieService = new CookieService();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [shopUUID, setShopUUID] = useState(null);

  useEffect(() => {
    const unsubscribe = firebaseApp.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      user.getIdToken().then((idToken) => {
        setToken(idToken);
      });
    } else {
      setToken(null);
    }
  }, [user]);

  const updateToken = () => {
    if (user) {
      user.getIdToken().then((idToken) => {
        setToken(idToken);
      });
    }
  };

  const signOut = () => {
    cookieService.postDeleteCookie();
    navigate("/login");
    firebaseApp
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        setToken(null);
        setUserData(null);
        setShopUUID(null);
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userData,
        setUserData,
        token,
        updateToken,
        shopUUID,
        setShopUUID,
        signOut,
      }}>
      {children}
    </UserContext.Provider>
  );
};
