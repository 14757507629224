import { firebaseApp } from "../firebase/firebase";

let idToken = null;

const setIdToken = async () => {
  const user = firebaseApp.auth().currentUser;
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = null;
  }
};

firebaseApp.auth().onAuthStateChanged(async (user) => {
  if (user) {
    idToken = await user.getIdToken();
  } else {
    idToken = null;
  }
});

export const setAuthorizationHeader = (api) => {
  if (idToken) {
    api.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

export const refreshIdToken = async (api) => {
  await setIdToken();
  setAuthorizationHeader(api);
};



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  refreshIdToken,
  setAuthorizationHeader,
};
