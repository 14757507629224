import React, { useEffect } from "react";
import { useState } from "react";
import "firebase/compat/auth";
import Routes from "./routes/Routes.js";
import Login from "./pages/Login/Login.js";
import Register from "./pages/Register/Register.js";
import ThemeProvider from "./services/utils/Themes/ThemeProvider.js";
import { UserProvider } from "./contexts/UserContext.js";
import useSessionManagement from "./services/session_management.js";
import { firebaseApp } from "./firebase/firebase.js";
import Loading from "./pages/Loading/Loading.js";
import { postCreateCookieEndpoint } from "./services/api_endpoints.js";

let activityTimeout;

function resetTimeout() {
  clearTimeout(activityTimeout);
  activityTimeout = setTimeout(renewSession, 5 * 60 * 1000); 
}

function renewSession() {
  postCreateCookieEndpoint({ cookie: getCookie('session') });
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function App() {
  const [isRegister, setIsRegister] = useState(false);
  useSessionManagement();
  const handleToggle = () => setIsRegister(!isRegister);

  useEffect(() => {
    window.onload = resetTimeout;
    window.onmousemove = resetTimeout;
    window.onkeypress = resetTimeout;
    window.onclick = resetTimeout;

    resetTimeout();

    return () => {
      window.onload = null;
      window.onmousemove = null;
      window.onkeypress = null;
      window.onclick = null;
    };
  }, []);

  return (
    <UserProvider>
      <ThemeProvider>
        <div className="App">
          <Content isRegister={isRegister} handleToggle={handleToggle} />
        </div>
      </ThemeProvider>
    </UserProvider>
  );
}

function Content({ isRegister, handleToggle }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebaseApp.auth().onAuthStateChanged((authUser) => {
      setLoading(false);
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return user ? (
    <Routes />
  ) : isRegister ? (
    <Register handleToggle={handleToggle} />
  ) : (
    <Login handleToggle={handleToggle} />
  );
}

export default App;
