import React from "react";
import TextInputField from "../../../components/TextInputField/TextInputField";
import CustomerInfo from "../../../assets/Images/Onboarding/undraw_my_location_re_r52x.svg";

export default function OnboardingPageTwo({ formData, onChange }) {
  const handleAddressChange = (index, field, value) => {
    const newAddress = [...formData.address];
    newAddress[index] = { ...newAddress[index], [field]: value };
    onChange("address", newAddress);
  };

  return (
    <div className="OnboardingPageTwo">
      <div className="title-container">
        <img src={CustomerInfo} alt=""></img>
      </div>
      <div>
        <h3>Wo können Ihre Kunden Sie finden?</h3>
      </div>

      <div className="input-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px 0",
            width: "300px",
            margin: "10px 0",
          }}>
          <TextInputField
            type="text"
            label="Street"
            value={formData.address[0]?.street || ""}
            onChange={(e) => handleAddressChange(0, "street", e.target.value)}
            required
          />
          <TextInputField
            type="text"
            label="House Number"
            required
            value={formData.address[0]?.houseNumber || ""}
            onChange={(e) =>
              handleAddressChange(0, "houseNumber", e.target.value)
            }
          />
          <TextInputField
            type="text"
            label="Postal Code"
            value={formData.address[0]?.postalCode || ""}
            onChange={(e) =>
              handleAddressChange(0, "postalCode", e.target.value)
            }
            required
          />
          <TextInputField
            type="text"
            label="City"
            value={formData.address[0]?.city || ""}
            onChange={(e) => handleAddressChange(0, "city", e.target.value)}
            required
          />
          <TextInputField
            type="text"
            label="Additional Information"
            value={formData.address[0]?.addition || ""}
            onChange={(e) => handleAddressChange(0, "addition", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
