import React from "react";
import Avatar from "../../assets/Images/Avatar.svg";

import "./Header.css";

export default function Header() {
  return (
    <div className="Header">
      <div className="Logo"></div>
      <div className="Avatar">
        <img src={Avatar} alt="Avatar" />
      </div>
    </div>
  );
}
