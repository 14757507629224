// src/components/Table/OrderTable/OrderTable.js
import React from "react";
import "./OrderTable.css";

const OrderTable = ({ orders, onOrderClick, getLastOrderState }) => {
  return (
    <div className="order-table-overview-container">
      <table>
        <thead>
          <tr>
            <th className="">Bestellnummer</th>
            <th>Datum</th>
            <th>Kunde</th>
            <th>Adresse</th>
            <th>Bezahlungsstatus</th>
            <th>Liefermethode</th>
            <th>Lieferstatus</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((orderObj) => (
            <tr key={orderObj.id} onClick={() => onOrderClick(orderObj.id)}>
              <td data-label="Bestellnummer">{orderObj.globalOrderId}</td>
              <td data-label="Datum">
                {new Date(orderObj.dateOfOrder).toLocaleDateString("de-DE", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </td>
              <td data-label="Kunde">Patrick Schreier</td>
              <td data-label="Adresse">{orderObj.deliveryAddress.street}</td>
              <td data-label="Bezahlungsstatus">
                {orderObj.paymentStatus || "-"}
              </td>
              <td data-label="Liefermethode">
                {orderObj.deliveryMethod || "-"}
              </td>
              <td data-label="Lieferstatus">{"-"}</td>
              <td data-label="Status">
                {getLastOrderState(orderObj.statusHistory)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
