import React from "react";
import "./Checkbox.css";

export default function Checkbox({ label, checked, onChange }) {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className="CheckboxContainer">
      <input
        type="checkbox"
        id="customCheckbox"
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor="customCheckbox">{label}</label>
    </div>
  );
}
