import React from "react";
import "./Button.css";

export default function CustomButton({
  label,
  type = "primary",
  variant = "filled",
  disabled = false,
  onClick,
  icon,
}) {
  const className = `ButtonComponent ${type} ${variant} ${
    disabled ? "disabled" : ""
  }`;

  return (
    <div className={className}>
      <button disabled={disabled} onClick={onClick}>
        {icon && icon}
        {label}
      </button>
    </div>
  );
}
