import { useEffect, useState } from "react";

const applyTheme = (theme) => {
  const root = document.documentElement;
  if (theme === "dark") {
    root.classList.add("theme-dark");
    root.classList.remove("theme-light");
  } else {
    root.classList.add("theme-light");
    root.classList.remove("theme-dark");
  }
};

const ThemeProvider = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setIsDarkMode(storedTheme === "dark");
      applyTheme(storedTheme);
    } else {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      setIsDarkMode(darkThemeMq.matches);
      applyTheme(darkThemeMq.matches ? "dark" : "light");
    }

    const updateTheme = (e) => {
      setIsDarkMode(e.matches);
      applyTheme(e.matches ? "dark" : "light");
    };

    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addEventListener("change", updateTheme);

    return () => {
      darkThemeMq.removeEventListener("change", updateTheme);
    };
  }, []);

  return children;
};
export default ThemeProvider;