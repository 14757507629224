import { v4 as uuidv4 } from "uuid";
import { ShopService } from "../../services/shop_service";
const shopService = new ShopService();

export const createShop = async (shopData, user) => {
  const uuid = uuidv4();
  const userId = user.uid;

  const shopObject = {
    id: uuid,
    shopDetailsId: uuid,
    name: shopData.name,
    userList: {
      [userId]: "SHOPADMIN",
    },
    phoneNumber: 123, //shopData.contact.phoneNumber,
    emailAddress: "shopData.contact.email",
    websiteUrl: "shopData.websiteUrl",
    address: {
      street: shopData.address[0].street,
      houseNumber: shopData.address[0].houseNumber,
      postalCode: shopData.address[0].postalCode,
      city: shopData.address[0].city,
      addition: shopData.address[0].addition,
    },
    itemImageUrls: {},
    isActive: true,
    taxNumber: shopData.bankInfo[0].taxNumber,
    maxDeliveryDistance: shopData.maxDeliveryDistance,
    lastInvoiceNo: 0,
    lastOrderNo: 0,
    productLimit: 0,
    openingTimes: shopData.openingTimes,
    paymentMethods: shopData.paymentMethods,
    deliveryMethods: shopData.deliveryMethods,
    createdBy: user.id,
    createdAt: new Date().toISOString(),
    updatedBy: user.id,
    updatedAt: new Date().toISOString(),
  };
  try {
    const response = await shopService.postCreateShop(shopObject);
    return response;
  } catch (error) {
    console.error("Fehler beim Erstellen des Shops:", error);
  }
};
