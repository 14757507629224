import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextInputField from "../../components/TextInputField/TextInputField";
import CustomButton from "../../components/Button/Button";
import "./ProductEdit.css";
import { ShopService } from "../../services/shop_service";

const shopService = new ShopService();

const ProductEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state;
  const [productData, setProductData] = useState(product);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };

// eslint-disable-next-line  
const deleteImage = async (imageType, type) => {
    try {
      const response = await shopService.deleteImageShop(imageType);
      if (response.status === 200) {
        setProductData((prevState) => {
          const updatedImageUrls = { ...prevState.images, [type]: "" };
          return { ...prevState, images: updatedImageUrls };
        });
        console.info("Bild erfolgreich gelöscht.");
      } else {
        console.error("Fehler beim Löschen des Bildes:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Löschen des Bildes:", error);
    }
  };

  const handleImageUpload = async (imageType, event) => {
    const file = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await shopService.putImageShop(
        product.id,
        imageType,
        formData
      );

      if (response.status === 200) {
        const imageUrl = response.data;
        setProductData((prevState) => ({
          ...prevState,
          images: {
            ...prevState.images,
            [imageType === "shopImageURL" ? "product_1" : "product_2"]:
              imageUrl,
          },
        }));
        console.info("Bild hochgeladen:", imageUrl);
      } else {
        console.error("Fehler beim Hochladen des Bildes:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Hochladen des Bildes:", error);
    }
  };

  const handleSave = () => {
    navigate("/csv"); // Navigieren Sie zurück zur CSV-Seite oder wo auch immer nötig
  };

  return (
    <div className="product-edit-container">
      <h1>Produkt bearbeiten</h1>
      <div className="product-edit-form-container">
        <div className="product-edit-form-split">
          <div className="product-edit-left-side">
            <div className="product-details-container">
              <TextInputField
                name="name"
                label="Produktname"
                value={productData.name}
                onChange={handleInputChange}
              />
              <TextInputField
                name="ean"
                label="EAN"
                value={productData.ean}
                onChange={handleInputChange}
              />
              <TextInputField
                name="articleNumber"
                label="Artikelnummer"
                value={productData.articleNumber}
                onChange={handleInputChange}
              />
              <TextInputField
                name="number"
                label="Preis (brutto)"
                value={productData.name}
                onChange={handleInputChange}
              />
              <TextInputField
                name="ean"
                label="EAN"
                value={productData.ean}
                onChange={handleInputChange}
              />
              <TextInputField
                name="articleNumber"
                label="Artikelnummer"
                value={productData.articleNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="product-edit-right-side">
            <div className="product-edit-images">
              <div className="image-wrapper">
                <img
                  src={productData.image || "default-image-url"}
                  alt="Produktbild"
                  className="full-width-image"
                />
                <div className="upload-overlay">
                  <label htmlFor="productImageUpload">
                    <img
                      src="upload-icon-url"
                      alt="Upload Icon"
                      className="upload-icon"
                    />
                    <input
                      type="file"
                      id="productImageUpload"
                      accept="image/*"
                      onChange={(e) => handleImageUpload("image", e)}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomButton
          label="Speichern"
          type="primary"
          variant="filled"
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default ProductEdit;
