import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import OrderOverview from "../pages/OrderOverview/OrderOverview";
import Administration from "../pages/Administration/Administration";
import Navbar from "../components/Navbar/Navbar";
import "./Routes";
import Header from "../components/Header/Header";
import Onboarding from "../pages/Onboarding/Onboarding";
import ShopManagement from "../pages/ShopManagement/ShopManagement";
import "./Routes.css";
import ProductTable from "../pages/ArticleManagement/ProductTable";
import ProductCreation from "../pages/ArticleManagement/ProductCreation/ProductCreation";
import ViewingOrder from "../pages/OrderOverview/ViewingOrder/ViewingOrder";
import CSVDisplay from "../pages/CSVImport/CSVDisplay";
import ProductEdit from "../pages/CSVImport/ProductEditCSV";
import Loading from "../pages/Loading/Loading";

export default function Directions() {
  const path = useLocation();

  return (
    <div className="Routes">
      {!path.pathname.includes("/login") && <Header />}
      {!path.pathname.includes("/onboarding") &&
        !path.pathname.includes("/login") && <Navbar />}
      <main>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Register />} />
          <Route path="/onboarding/:step" element={<Onboarding />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/order" element={<OrderOverview />} />
          <Route path="/orderview" element={<ViewingOrder />} />
          <Route path="/articleManagement" element={<ProductTable />} />
          <Route path="/administration" element={<Administration />} />
          <Route path="/shopmanagement" element={<ShopManagement />} />
          <Route path="/productCreation" element={<ProductCreation />} />
          <Route path="/csv" element={<CSVDisplay />} />
          <Route path="/productEdit" element={<ProductEdit />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="*" element={<h1>404 - Seite nicht gefunden</h1>} />
        </Routes>
      </main>
    </div>
  );
}
