import React from "react";
import { useLocation } from "react-router-dom";
import "./ViewingOrder.css";
import LocaloraNewLogo from "../../../assets/Images/BannerShop.svg";

export default function ViewingOrder() {
  const location = useLocation();
  const order = location.state?.order || {};
  const customer = location.state?.customer || {};

  const formatDate = (isoDate) => {
    try {
      if (!isoDate) return "";

      const date = new Date(isoDate);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC", 
      };
      return date.toLocaleDateString("de-DE", options);
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  return (
    <div className="viewing-order-container">
      <h1>{order.globalOrderId}</h1>
      <p>{formatDate(order.dateOfOrder)}</p>

      <div className="order-information-container">
        <div className="order-information">
          <div>
            <h4>Kunde:</h4>
            <p>{customer}</p>
          </div>
          <div>
            <h4>Adresse:</h4>
            <p>{order.deliveryAddress.street}</p>
            <p>
              {order.deliveryAddress.postalCode} {order.deliveryAddress.city}
            </p>
          </div>
          <div>
            <h4>Bezahlmethode:</h4>
            <p>{order.paymentMethod}</p>
          </div>
          <div>
            <h4>Liefermethode:</h4>
            <p>{order.deliveryMethod}</p>
          </div>
        </div>
        <div className="product-overview">
          <table>
            <thead>
              <tr>
                <th>Bild</th>
                <th className="product">Produkt</th>
                <th>Menge</th>
                <th>Artikel-Nr:</th>
                <th>Preis pro Einheit:</th>
                <th>Summe:</th>
              </tr>
            </thead>
            <tbody>
              {order.products.map((product) => (
                <tr key={product.id}>
                  <td>
                    <img
                      src={product.images.thumbnail0 || LocaloraNewLogo}
                      alt=""
                    />
                  </td>
                  <td className="product">{product.name}</td>
                  <td>x{product.basePriceAmount}</td>
                  <td>{product.articleNumber}</td>
                  <td>{product.basePricePerUnit}</td>
                  <td>{product.basePricePerUnit * product.basePriceAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="bottom-container">
            <p className="tax bold">Steuer:</p>
            <p className="text-size">{order.tax.toFixed(2)}€</p>
            <p className="text-size bold">Gesamt:</p>
            <p className="total">{order.totalPriceGross.toFixed(2)}€</p>
          </div>
        </div>
      </div>
    </div>
  );
}
