import {
  deleteProductEndpoint,
  getAllProductsEndpoint,
  getProductByIdEndpoint,
  getProductByShopIdAndCatEndpoint,
  getProductsByCategoryEndpoint,
  getProgetAllProductsByEANductByIdEndpoint,
  postCreateProductEndpoint,
  putUpdateProductEndpoint,
} from "./api_endpoints";

export class ProductService {
  async getProductById(id) {
    try {
      const response = await getProductByIdEndpoint(id);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getProgetAllProductsByEANductById(ean) {
    try {
      const response = await getProgetAllProductsByEANductByIdEndpoint(ean);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getProductByShopIdAndCat(id, category) {
    try {
      const response = await getProductByShopIdAndCatEndpoint(id, category);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getProductsByCategory(category) {
    try {
      const response = await getProductsByCategoryEndpoint(category);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getAllProducts() {
    try {
      const response = await getAllProductsEndpoint();
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async postCreateProduct(product) {
    try {
      const response = await postCreateProductEndpoint(product);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async putUpdateProduct(product) {
    try {
      const response = await putUpdateProductEndpoint(product);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async deleteProduct(id) {
    try {
      const response = await deleteProductEndpoint(id);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }
}
