import { useEffect } from "react";
import { firebaseApp } from "../firebase/firebase";
import "firebase/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const checkAndLogout = async () => {
  const sessionCookie = Cookies.get("session");
  if (sessionCookie) {
    const decodedCookie = JSON.parse(atob(sessionCookie.split(".")[1]));
    const expiryTime = decodedCookie.exp * 1000;
    const currentTime = new Date().getTime();

    if (currentTime >= expiryTime) {
      await firebaseApp.auth().signOut();
      Cookies.remove("session"); 
      return true;
    }
  } else {
    await firebaseApp.auth().signOut();
    return true;
  }
  return false;
};

const useSessionManagement = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const shouldLogout = await checkAndLogout();
      if (shouldLogout) {
        // navigate('/login'); 
      }
    }, 60 * 1000); 


    return () => clearInterval(intervalId);
  }, [navigate]);
};

export default useSessionManagement;
