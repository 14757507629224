import {
  getUserByEmailEndpoint,
  getUserByIdEndpoint,
  getUserByFirebaseIdEndpoint,
  getAllUserEndpoint,
  postCreateUserEndpoint,
  putUpdateUserEndpoint,
} from "./api_endpoints";

export class UserService {
  async getUserByEmail(email) {
    try {
      const response = await getUserByEmailEndpoint(email);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen des Users mit der E-Mail", error);
    }
  }

  async getUserById(id) {
    try {
      const response = await getUserByIdEndpoint(id);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen des Users mit der ID", error);
    }
  }

  async getUserByFirebaseId(firebaseId) {
    try {
      const response = await getUserByFirebaseIdEndpoint(firebaseId);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen des Users mit der FirebaseID", error);
    }
  }

  async getAllUsers() {
    try {
      const response = await getAllUserEndpoint();
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der User", error);
    }
  }

  async postCreateUser(user) {
    try {
      const response = await postCreateUserEndpoint(user);
      return response;
    } catch (error) {
      console.error("Fehler beim Erstellen des Users", error);
    }
  }

  async putUpdateUser(user) {
    try {
      const response = await putUpdateUserEndpoint(user);
      return response;
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Users", error);
    }
  }
}
