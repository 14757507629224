import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardingPageOne from "./OnboardingPages/OnboardingPageOne";
import OnboardingPageTwo from "./OnboardingPages/OnboardingPageTwo";
import OnboardingPageThree from "./OnboardingPages/OnboardingPageThree";
import OnboardingPageFour from "./OnboardingPages/OnboardingPageFour";
import OnboardingPageFive from "./OnboardingPages/OnboardingPageFive";
import CustomButton from "../../components/Button/Button";
import Stepper from "../../components/Stepper/Stepper";
import "./Onboarding.css";
import { createShop } from "../ShopManagement/ShopManagementAPI";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { updateUser } from "../Login/UserAPI";
import {
  validateForm,
  handleNextStep,
  handlePrevStep,
  handleFormDataChange,
} from "./OnboardingUtils";

export default function Onboarding() {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const { userData } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: "",
    address: [
      {
        street: "",
        houseNumber: "",
        postalCode: "",
        city: "",
        addition: "",
      },
    ],
    maxDeliveryDistance: 0,
    deliveryMethods: [],
    openingTimes: [
      { day: "monday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "tuesday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "wednesday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "thursday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "friday", times: [{ start: "09:00", end: "17:00" }] },
      { day: "saturday", times: [] },
      { day: "sunday", times: [] },
    ],
    bankInfo: [
      {
        bankName: "",
        iban: "",
        bic: "",
        taxNumber: "",
      },
    ],
  });

  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const step =
      parseInt(location.pathname.split("/")[2].replace("step", "")) - 1;
    setCurrentStep(step);
  }, [location]);

  useEffect(() => {
    setFormValid(validateForm(currentStep, formData));
  }, [formData, currentStep]);

  return (
    <div className="Onboarding">
      <div className="output">
        {currentStep === 0 && (
          <OnboardingPageOne
            formData={formData}
            onChange={(key, value) =>
              handleFormDataChange(key, value, setFormData)
            }
          />
        )}
        {currentStep === 1 && (
          <OnboardingPageTwo
            formData={formData}
            onChange={(key, value) =>
              handleFormDataChange(key, value, setFormData)
            }
          />
        )}
        {currentStep === 2 && (
          <OnboardingPageThree
            formData={formData}
            onChange={(key, value) =>
              handleFormDataChange(key, value, setFormData)
            }
          />
        )}
        {currentStep === 3 && (
          <OnboardingPageFour
            formData={formData}
            onChange={(key, value) =>
              handleFormDataChange(key, value, setFormData)
            }
          />
        )}
        {currentStep === 4 && (
          <OnboardingPageFive
            formData={formData}
            onChange={(key, value) =>
              handleFormDataChange(key, value, setFormData)
            }
          />
        )}
      </div>
      <div className="bottom-container">
        <div className="stepper-container">
          <Stepper currentStep={currentStep} totalSteps={5} />
        </div>
        <div className="btn-container">
          <CustomButton
            label="zurück"
            type="primary"
            variant="text"
            onClick={() =>
              handlePrevStep(currentStep, setCurrentStep, navigate)
            }
            disabled={currentStep === 0}
          />
          <CustomButton
            label={currentStep < 4 ? "weiter" : "beenden"}
            type="primary"
            variant="filled"
            onClick={() =>
              handleNextStep(
                currentStep,
                setCurrentStep,
                navigate,
                formValid,
                formData,
                user,
                userData,
                updateUser,
                createShop
              )
            }
            disabled={!formValid}
          />
        </div>
      </div>
    </div>
  );
}
