import {
  deleteOrderEndpoint,
  getAllOrdersByShopIdEndpoint,
  getAllOrdersEndpoint,
  getAllOrdersFromUserEndpoint,
  getOrderByIdEndpoint,
  postCreateOrderEndpoint,
  putSetOrdersAsCanceledEndpoint,
  putUpdateOrderEndpoint,
} from "./api_endpoints";

export class OrderService {
  async getOrderById(id) {
    try {
      const response = await getOrderByIdEndpoint(id);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getAllOrders() {
    try {
      const response = await getAllOrdersEndpoint();
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getAllOrdersFromUser(id) {
    try {
      const response = await getAllOrdersFromUserEndpoint(id);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getAllOrdersByShopId(id, isCanceled) {
    try {
      const response = await getAllOrdersByShopIdEndpoint(id, isCanceled);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async postCreateOrder(order) {
    try {
      const response = await postCreateOrderEndpoint(order);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async putSetOrdersAsCanceled(id, cancelReason) {
    try {
      const response = await putSetOrdersAsCanceledEndpoint(id, cancelReason);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async putUpdateOrder(order) {
    try {
      const response = await putUpdateOrderEndpoint(order);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async deleteOrder(name) {
    try {
      const response = await deleteOrderEndpoint(name);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }
}
