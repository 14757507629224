import {
  getAllCategoriesEndpoint,
  getCategoryByIdEndpoint,
} from "./api_endpoints";

export class CategoryService {
  async getCategoryById(id) {
    try {
      const response = await getCategoryByIdEndpoint(id);
      return response.data;
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Kategorien:", error);
      throw error;
    }
  }
  async getAllCategories() {
    try {
      const response = await getAllCategoriesEndpoint();
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }
}
