import React from "react";
import "./ProductTableSkeleton.css";

const TableSkeletonLoader = () => {
  return (
    <table className="product-skeleton-table">
      <thead>
        <tr>
          <th>Bild</th>
          <th>Name</th>
          <th>Status</th>
          <th>Artikelnummer</th>
          <th>Kategorie</th>
          <th>Bestand</th>
          <th>Einheit</th>
          <th>Stückzahl pro Produkt</th>
          <th>Preis Brutto</th>
          <th>Preis Netto</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 10 }).map((_, index) => (
          <tr key={index}>
            <td>
              <div className="skeleton-cell skeleton-img"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
            <td>
              <div className="skeleton-cell skeleton-text"></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableSkeletonLoader;
