import React from "react";
import "./DistanceSlider.css";

const DistanceSlider = ({ value, onChange }) => {
  const handleChange = (event) => {
    onChange(parseInt(event.target.value));
  };

  return (
    <div className="distance-slider">
      <p>
        <span>{value}</span>km
      </p>
      <div className="slider-container">
        <input
          type="range"
          min="1"
          max="99"
          value={value}
          onChange={handleChange}
          className="slider"
        />
      </div>
    </div>
  );
};

export default DistanceSlider;
