import React, { useContext, useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import CsvFileInput from "./CsvFileInput";
import ProductTableContentCSV from "./ProductTableContentCSV";
import LocaloraNewLogo from "../../assets/Images/BannerShop.svg";
import { ProductService } from "../../services/product_service";
import CustomButton from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

import { CategoryService } from "../../services/category_service";
import { UserContext } from "../../contexts/UserContext";
import "./CSVDisplay.css";
import { ShopService } from "../../services/shop_service";

export default function CSVDisplay() {
  const productService = new ProductService();
  const shopService = new ShopService();

  const { user, shopUUID, setShopUUID } = useContext(UserContext);

  const navigate = useNavigate();
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const categoryService = new CategoryService();
  // eslint-disable-next-line
  const [categoryOptions, setCategoryOptions] = useState([
    { value: "", label: "Alle Kategorien" },
  ]);

  const [categoryMapping, setCategoryMapping] = useState({});

  useEffect(() => {
    const loadUserShops = async () => {
      try {
        const response = await shopService.getUserShops();
        setShopUUID(response[0].id);
      } catch (error) {
        console.error("Fehler beim Laden der Nutzershops:", error);
      }
    };
    loadUserShops();
    // eslint-disable-next-line
  }, [setShopUUID]);

  const handleFileLoad = (csvData) => {
    const mappedData = csvData.map((row) => ({
      id: uuidv4(),
      shopId: shopUUID,
      name: row.name || "",
      ean: row.ean || "",
      articleNumber: row.articleNumber || "",
      priceGross: row.priceGross || "",
      priceNet: row.priceNet || "",
      tax: row.tax || 0.19,
      description: row.description || "",
      measureUnit: row.measureUnit || "",
      amount: row.amount || "",
      productAmount: row.productAmount || "",
      basePriceUnit: row.basePriceUnit || "",
      basePricePerUnit: row.basePricePerUnit || "",
      basePriceAmount: row.basePriceAmount || "",
      inShopStock: row.stock || "",
      categoryId: row.categoryId || "",
      tags: row.tags ? row.tags.split(",") : [],
      flagActive: row.flagActive !== undefined ? row.flagActive : true,
      deliveryMethod: row.deliveryMethod || "",
      images: {
        product_1: row.product_1 || LocaloraNewLogo,
        product_2: row.product_2 || LocaloraNewLogo,
      },
      createdBy: user.id,
      createdAt: new Date().toISOString(),
      updatedBy: user.id,
      updatedAt: new Date().toISOString(),
    }));

    setData(mappedData);
    setSortedProducts(mappedData);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categoryService.getAllCategories();
        const categoryOptions = response.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        const categoryMapping = response.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});

        setCategoryOptions([
          { value: "", label: "Alle Kategorien" },
          ...categoryOptions,
        ]);

        setCategoryMapping(categoryMapping);
      } catch (error) {
        console.error("Fehler beim Abrufen der Kategorien:", error);
      }
    };
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  const sortTable = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    const sortedArray = [...sortedProducts].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setSortedProducts(sortedArray);
    setSortConfig({ key, direction });
  };

  const handleProductClick = (id) => {
    const product = sortedProducts.find((product) => product.id === id);
    navigate("/productCreation", { state: { product } });
  };
  const handleUploadSelected = async () => {
    const productsToUpload = sortedProducts.filter((product) =>
      selectedProducts.includes(product.id)
    );

    try {
      if (productsToUpload.length !== 0) {
        for (const product of productsToUpload) {
          await productService.postCreateProduct(product);
        }
        alert("Ausgewählte Produkte wurden erfolgreich hochgeladen");
        setSelectedProducts([]); // Clear selected products after upload
      } else {
        alert("Es wurden keine Produkte ausgewählt");
      }
    } catch (error) {
      console.error("Fehler beim Hochladen der Produkte:", error);
      alert("Fehler beim Hochladen der Produkte");
    }
  };

  const handleSelectAll = () => {
    if (selectedProducts.length === sortedProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(sortedProducts.map((product) => product.id));
    }
  };

  return (
    <div className="csvBody">
      <h1>Artikelimport</h1>
      <div className="importInput">
        <CsvFileInput onFileLoad={handleFileLoad} />

        <div className="select-button-container">
          <CustomButton
            label="Alle auswählen"
            type="tertiary"
            variant="text"
            onClick={handleSelectAll}
          />
          <CustomButton
            label="Ausgewählte hochladen"
            type="primary"
            variant="filled"
            onClick={handleUploadSelected}
          />
        </div>
      </div>

      <ProductTableContentCSV
        onProductClick={handleProductClick}
        sortedProducts={sortedProducts}
        sortTable={sortTable}
        categoryMapping={categoryMapping}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
      />
    </div>
  );
}
