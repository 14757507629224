import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { ProductService } from "../../services/product_service";
import Select from "../../components/Select/Select";
import TextInputField from "../../components/TextInputField/TextInputField";
import { ShopService } from "../../services/shop_service";
import { CategoryService } from "../../services/category_service";
import TableSkeletonLoader from "../../components/ProductTableSkeleton/ProductTableSkeleton";
import "./ProductTable.css";
import ProductTableContent from "../../components/Table/ProductTable/ProductTableContent";
import CustomButton from "../../components/Button/Button";

export default function ProductTable() {
  const shopService = new ShopService();
  const productService = new ProductService();
  const categoryService = new CategoryService();
  const [loading, setLoading] = useState(true);

  const { shopUUID, setShopUUID } = useContext(UserContext);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [sortSettings, setSortSettings] = useState({
    key: null,
    direction: null,
  });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([
    { value: "", label: "Alle Kategorien" },
  ]);

  const [categoryMapping, setCategoryMapping] = useState({});

  const statusOptionArray = [
    { value: "", label: "Aktiv & Inaktiv" },
    { value: "false", label: "Inaktiv" },
    { value: "true", label: "Aktiv" },
  ];

  useEffect(() => {
    const loadUserShops = async () => {
      try {
        const response = await shopService.getUserShops();
        if (response.length > 0) {
          setShopUUID(response[0].id);
        }
      } catch (error) {
        console.error("Fehler beim Laden der Nutzershops:", error);
      }
    };
    loadUserShops();
    // eslint-disable-next-line
  }, [setShopUUID]);

  useEffect(() => {
    const fetchProducts = async () => {
      // Skip fetch if shopUUID is not set
      if (!shopUUID) return;

      // Start loading
      setLoading(true);
      try {
        const response = await productService.getProductByShopIdAndCat(
          shopUUID,
          filterCategory
        );
        setProducts(response);
        setFilteredProducts(response);
      } catch (error) {
        console.error("Fehler beim Abrufen der Produkte:", error);
      }
      // End loading
      setLoading(false);
    };
    fetchProducts();
    // eslint-disable-next-line
  }, [shopUUID]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categoryService.getAllCategories();
        const categoryOptions = response.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        const categoryMapping = response.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});

        setCategoryOptions([
          { value: "", label: "Alle Kategorien" },
          ...categoryOptions,
        ]);

        setCategoryMapping(categoryMapping);
      } catch (error) {
        console.error("Fehler beim Abrufen der Kategorien:", error);
      }
    };
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  const onProductClick = async (productId) => {
    try {
      const product = await productService.getProductById({ id: productId });
      navigate("/productCreation", { state: { product } });
    } catch (error) {
      console.error("Fehler beim Abrufen des Produkts:", error);
    }
  };

  const handleCreateProduct = () => {
    navigate("/productCreation");
  };

  // Sort table based on key
  const sortTable = (key) => {
    let direction = "ascending";
    if (sortSettings.key === key && sortSettings.direction === "ascending") {
      direction = "descending";
    }
    setSortSettings({ key, direction });
  };

  // Sort products based on sort settings
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortSettings.key && sortSettings.direction) {
      if (sortSettings.direction === "ascending") {
        return a[sortSettings.key] - b[sortSettings.key];
      } else if (sortSettings.direction === "descending") {
        return b[sortSettings.key] - a[sortSettings.key];
      }
    }
    return 0;
  });

  // Filter products based on search term
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    filterProducts(event.target.value, filterCategory);
  };

  // Filter products based on category
  const handleCategoryFilter = (event) => {
    const category = event.target.value;
    setFilterCategory(category);
    filterProducts(searchTerm, category);
  };

  // Filter products based on status
  const handleStatusFilter = (event) => {
    const status = event.target.value;
    setFilterStatus(status);
    filterProducts(searchTerm, filterCategory, status);
  };

  // Filter products based on search term, category, and status
  const filterProducts = (searchTerm, category, status) => {
    let filtered = products.filter((product) => {
      let nameMatch = true;
      let categoryMatch = true;
      let statusMatch = true;

      if (
        searchTerm &&
        !product.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        nameMatch = false;
      }

      if (category && product.categoryId !== category) {
        categoryMatch = false;
      }

      if (status && String(product.flagActive) !== status) {
        statusMatch = false;
      }

      return nameMatch && categoryMatch && statusMatch;
    });

    setFilteredProducts(filtered);
  };

  function goToCSV(){
    navigate("/csv")
  }

  return (
    <div className="product-overview-container">
      <div className="product-overview-header">
        <h3>Produktübersicht</h3>
        <button
          style={{ fontSize: "40px", padding: "0" }}
          onClick={handleCreateProduct}
          className="create-product-button">
          +
        </button>
      </div>
      <CustomButton
          label="CSV Import"
          type="primary"
          variant="filled"
          onClick={goToCSV}
        />
      <div className="filter-search-container">
        <div className="item">
          <TextInputField
            className="search-input"
            type="text"
            label="Produkt suchen"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="item select">
          <Select
            value={filterCategory}
            onChange={handleCategoryFilter}
            option={categoryOptions}></Select>
        </div>
        <div className="item select">
          <Select
            value={filterStatus}
            onChange={handleStatusFilter}
            option={statusOptionArray}></Select>
        </div>
      </div>

      {loading ? (
        <TableSkeletonLoader />
      ) : (
        <ProductTableContent
          products={products}
          onProductClick={onProductClick}
          sortedProducts={sortedProducts}
          sortTable={sortTable}
          categoryMapping={categoryMapping}
          categoryOptions={categoryOptions}
          statusOptionArray={statusOptionArray}
          filterCategory={filterCategory}
          filterStatus={filterStatus}
          handleCategoryFilter={handleCategoryFilter}
          handleStatusFilter={handleStatusFilter}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          loading={loading}
        />
      )}
    </div>
  );
}
