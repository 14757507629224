import React from "react";
import WeekDayOpeningTime from "../../../components/WeekDayOpeningTime/WeekDayOpeningTime";
import CustomerInfo from "../../../assets/Images/Onboarding/undraw_time_management_re_tk5w.svg";
import "../Onboarding.css";

export default function OnboardingPageFour({ formData, onChange }) {
  const handleOpeningTimeChange = (day, times) => {
    const newOpeningTimes = formData.openingTimes.map((item) => {
      if (item.day === day) {
        return { ...item, times };
      }
      return item;
    });
    onChange("openingTimes", newOpeningTimes);
  };

  return (
    <div className="OnboardingPageFour">
      <div className="title-container">
        <img src={CustomerInfo} alt=""></img>
      </div>
      <div>
        <h3>Wie sind Ihre Öffnungszeiten?</h3>
      </div>
      <div className="input-container">
        <div className="weekday-container">
          {formData.openingTimes.map(({ day, times }) => (
            <div key={day}>
              <WeekDayOpeningTime
                day={day}
                defaultOpen={times.length > 0}
                openingTimes={times}
                onChange={(newTimes) => handleOpeningTimeChange(day, newTimes)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
