import axios from "axios";
import authService from "./auth_service";

const base_url = process.env.REACT_APP_API_URI;

export class APIClient {
  constructor() {
    this.api = axios.create({
      baseURL: base_url,
      headers: {
        Accept: "application/json",
      },
      withCredentials: true,
    });
    authService.setAuthorizationHeader(this.api);
  }

  async get(endpoint, params) {
    await authService.refreshIdToken(this.api);
    return this.api.get(endpoint, { params });
  }

  async post(endpoint, data) {
    await authService.refreshIdToken(this.api);
    return this.api.post(endpoint, data);
  }

  async put(endpoint, data) {
    await authService.refreshIdToken(this.api);
    return this.api.put(endpoint, data);
  }

  async delete(endpoint, data) {
    await authService.refreshIdToken(this.api);
    return this.api.delete(endpoint, { data });
  }
}
