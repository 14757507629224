import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBxjqBkqHBLu5aWF-DRw2ufzjBhQAEClC4",
  authDomain: "localora-dev.firebaseapp.com",
  projectId: "localora-dev",
  storageBucket: "localora-dev.appspot.com",
  messagingSenderId: "104539123544",
  appId: "1:104539123544:web:d472190ba9eb3d145d7f2d"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .then(() => {
    console.log("Session started");
  }
  )
  .catch((error) => {
    console.error("Error starting session:", error);
  });
  

export { firebaseApp, storage };