import React from "react";
import "./Select.css";

export default function Select({ value, onChange, option }) {
  return (
    <div className="select-container">
      <select className="category" value={value} onChange={onChange}>
        {option.map((obj) => (
          <option key={obj.value} value={obj.value}>
            {obj.label}
          </option>
        ))}
      </select>
    </div>
  );
}
