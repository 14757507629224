import React from "react";
import DistanceSlider from "../../../components/DistanceSlider/DistanceSlider";
import Checkbox from "../../../components/Checkbox/Checkbox";
import CustomerInfo from "../../../assets/Images/Onboarding/undraw_favourite_item_pcyo.svg";

export default function OnboardingPageThree({ formData, onChange }) {
  const handleDistanceChange = (value) => {
    onChange("maxDeliveryDistance", value);
  };

  // const handleDeliveryOptionChange = (option) => {
  //   const newOptions = formData.deliveryMethods?.includes(option)
  //     ? formData.deliveryOptions?.filter((item) => item !== option)
  //     : [...formData.deliveryOptions, option];
  //   onChange("deliveryMethods", newOptions);
  // };

  const handleDeliveryOptionChange = (option) => {
    const newOptions = formData.deliveryMethods.includes(option)
      ? formData.deliveryMethods.filter((item) => item !== option)
      : [...formData.deliveryMethods, option];
    onChange("deliveryMethods", newOptions);
  };

  return (
    <div className="OnboardingPageThree">
      <div className="title-container">
        <img src={CustomerInfo} alt=""></img>
      </div>
      <div>
        <h3>Ein paar Schritte noch!</h3>
      </div>
      <div className="input-container">
        <h4>In welcher Entfernung möchten Sie den Kunden angezeigt werden?</h4>
        <DistanceSlider
          value={formData.maxDeliveryDistance}
          onChange={handleDistanceChange}
        />
        <h4>Wie kommen die Kunden an Ihre Artikel?</h4>
        <div className="checkbox-container">
          <Checkbox
            label="Abholung"
            checked={formData.deliveryMethods?.includes("Pickup")}
            onChange={() => handleDeliveryOptionChange("Pickup")}
          />
          <Checkbox
            label="Lieferung"
            checked={formData.deliveryMethods?.includes("Delivery")}
            onChange={() => handleDeliveryOptionChange("Delivery")}
          />
        </div>
      </div>
    </div>
  );
}
