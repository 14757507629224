import React, { useEffect, useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Navbar.css";
import ThemeModeToggle from "../ThemeModeToggle/ThemeModeToggle";
import { FaChevronRight } from "react-icons/fa";
import { MdReceiptLong, MdShelves, MdDomain, MdLogout } from "react-icons/md";
import { ShopService } from "../../services/shop_service";
import { UserContext } from "../../contexts/UserContext";

export default function Navbar() {
  const { signOut } = useContext(UserContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();
  const [shop, setShop] = useState([]);
  const sidebarRef = useRef(null);

  const shopService = new ShopService();

  useEffect(() => {
    const fetchShopName = async () => {
      try {
        const shops = await shopService.getUserShops();
        setShop(shops);
      } catch (error) {
        console.error("Fehler beim Laden der Nutzershops:", error);
      }
    };
    fetchShopName();
    // eslint-disable-next-line
  }, []);

  // Close Sidebar while user is clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShowSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav
      ref={sidebarRef}
      className={`sidebar ${showSidebar ? "big" : "small"}`}>
      <div className="sidebar-container">
        <div>
          <div className="headline-container">
            {showSidebar && (
              <h2 className="headline">{shop?.map((obj) => obj.name)}</h2>
            )}
          </div>
          <div>
            <ul>
              {showSidebar ? (
                <div>
                  {/* <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}>
                    <CiCircleChevRight />
                    <p>Home</p>
                  </Link>
                </li> */}
                  <li>
                    <Link
                      to="/order"
                      className={
                        location.pathname === "/order" ? "active" : ""
                      }>
                      <MdReceiptLong />
                      <p>Bestellübersicht</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ArticleManagement"
                      className={
                        location.pathname === "/ArticleManagement"
                          ? "active"
                          : ""
                      }>
                      <MdShelves />
                      <p>Artikelverwaltung</p>
                    </Link>
                  </li>
                  {/* <li>
                  <Link
                    to="/Administration"
                    className={
                      location.pathname === "/Administration" ? "active" : ""
                    }>
                    <CiCircleChevRight />
                    <p>Administration</p>
                  </Link>
                </li> */}
                  <li>
                    <Link
                      to="/ShopManagement"
                      className={
                        location.pathname === "/ShopManagement" ? "active" : ""
                      }>
                      <MdDomain />
                      <p>ShopManagement</p>
                    </Link>
                  </li>
                  <li>
                    <div className="logout-button">
                      <button onClick={signOut}>
                        <MdLogout />
                        <p>Abmelden</p>
                      </button>
                    </div>
                  </li>
                </div>
              ) : (
                <div>
                  {/* <li>
                  <Link
                    to="/"
                    className={location.pathname === "/" ? "active" : ""}>
                    <CiCircleChevRight />
                  </Link>
                </li> */}
                  <li>
                    <Link
                      to="/order"
                      className={
                        location.pathname === "/order" ? "active" : ""
                      }>
                      <MdReceiptLong />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/ArticleManagement"
                      className={
                        location.pathname === "/ArticleManagement"
                          ? "active"
                          : ""
                      }>
                      <MdShelves />
                    </Link>
                  </li>
                  {/* <li>
                  <Link
                    to="/Administration"
                    className={
                      location.pathname === "/Administration" ? "active" : ""
                    }>
                    <CiCircleChevRight />
                  </Link>
                </li> */}
                  <li>
                    <Link
                      to="/ShopManagement"
                      className={
                        location.pathname === "/ShopManagement" ? "active" : ""
                      }>
                      <MdDomain />
                    </Link>
                  </li>
                  <li>
                    <div className="logout-button">
                      <button onClick={signOut}>
                        <MdLogout />
                      </button>
                    </div>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </div>
        <div className="bottom-container">
          <div className="theme-toggle">
            <ThemeModeToggle />
          </div>
          <div className="open-close-button">
            <button onClick={() => setShowSidebar(!showSidebar)}>
              {showSidebar ? (
                <FaChevronRight className="close-sidebar" />
              ) : (
                <FaChevronRight className="open-sidebar" />
              )}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
