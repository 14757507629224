import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { v4 as uuidv4 } from "uuid";
import TextInputField from "../../../components/TextInputField/TextInputField";
import Switch from "../../../components/Switch/Switch";
import Counter from "../../../components/Counter/Counter";
import UploadIcon from "../../../assets/Images/uploadImage.svg";
import LocaloraNewLogo from "../../../assets/Images/BannerShop.svg";
import "./ProductCreation.css";
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "../../../components/TextArea/TextArea";
import CustomButton from "../../../components/Button/Button";
import { ProductService } from "../../../services/product_service";
import Select from "../../../components/Select/Select";
import { CategoryService } from "../../../services/category_service";
import { ShopService } from "../../../services/shop_service";

const shopService = new ShopService();

export default function ProductCreation() {
  const { user } = useContext(UserContext);
  const { shopUUID } = useContext(UserContext);
  const location = useLocation();
  const product = location.state?.product || {};
  const navigate = useNavigate();
  const productService = new ProductService();
  const categoryService = new CategoryService();
  const [categoryOptions, setCategoryOptions] = useState([
    { value: "", label: "Alle Kategorien" },
  ]);

  // Fetching categories
  // eslint-disable-next-line
  const [categoryMapping, setCategoryMapping] = useState({});
  // eslint-disable-next-line
  const [selectedCategorie, setSelectedCategorie] = useState("");

  // New or existing article
  const isEditing = !!product?.id;

  // Generating UUID if new article
  const articleUUID = !isEditing ? uuidv4() : product.id;

  const [productData, setProductData] = useState({
    id: articleUUID || product?.id,
    shopId: shopUUID || product?.shopId,
    name: product?.name || "",
    ean: product?.ean || "",
    articleNumber: product?.articleNumber || "",
    priceGross: product?.priceGross || "",
    priceNet: product?.priceNet || "",
    tax: product?.tax || 0.19,
    description: product?.description || "",
    measureUnit: product?.measureUnit || "",
    amount: product?.amount || "",
    productAmount: product?.productAmount || "",
    basePriceUnit: product?.basePriceUnit || "",
    basePricePerUnit: product?.basePricePerUnit || "",
    basePriceAmount: product?.basePriceAmount || "",
    inShopStock: product?.stock || "",
    categoryId: product?.categoryId || "",
    tags: product?.tags || [],
    flagActive: product?.flagActive || true,
    deliveryMethod: product?.deliveryMethod || "",
    images: {
      product_1: product?.images?.product_1 || LocaloraNewLogo,
      product_2: product?.images?.product_2 || LocaloraNewLogo,
    },
    createdBy: product?.createdBy || user.id,
    createdAt: product?.createdAt || new Date().toISOString(),
    updatedBy: product?.updatedBy || user.id,
    updatedAt: product?.updatedAt || new Date().toISOString(),
  });

  const [isActive, setIsActive] = useState(product?.flagActive);

  const handleSave = async () => {
    try {
      if (isEditing) {
        await productService.putUpdateProduct(productData);
        alert("Produkt wurde erfolgreich aktualisiert");
      } else {
        await productService.postCreateProduct(productData);
        alert("Produkt wurde erfolgreich erstellt");
        navigate("/ArticleManagement");
      }
    } catch (error) {
      console.error("Fehler beim Speichern des Produkts:", error);
      alert("Fehler beim Speichern des Produkts");
    }
  };

  const handleToggle = (value) => {
    setIsActive(value);
    setProductData({ ...productData, flagActive: value });
  };

  const handleChange = (field, value) => {
    setSelectedCategorie(value);
    setProductData({ ...productData, [field]: value });
  };

  const deleteImage = async (imageType, type) => {
    try {
      const response = await shopService.deleteImageShop(imageType);
      if (response.status === 200) {
        setProductData((prevState) => {
          const updatedImageUrls = { ...prevState.images, [type]: "" };
          return { ...prevState, images: updatedImageUrls };
        });
        console.info("Bild erfolgreich gelöscht.");
      } else {
        console.error("Fehler beim Löschen des Bildes:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Löschen des Bildes:", error);
    }
  };

  const handleImageUpload = async (imageType, event) => {
    const file = event.target.files[0];
    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await shopService.putImageShop(
        articleUUID,
        imageType,
        formData
      );

      if (response.status === 200) {
        const imageUrl = response.data;
        setProductData((prevState) => ({
          ...prevState,
          images: {
            ...prevState.images,
            [imageType === "shopImageURL" ? "product_1" : "product_2"]:
              imageUrl,
          },
        }));
        console.info("Bild hochgeladen:", imageUrl);
      } else {
        console.error("Fehler beim Hochladen des Bildes:", response.statusText);
      }
    } catch (error) {
      console.error("Fehler beim Hochladen des Bildes:", error);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categoryService.getAllCategories();
        const categoryOptions = response.map((category) => ({
          value: category.id,
          label: category.name,
        }));

        const categoryMapping = response.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});

        setCategoryOptions([
          { value: "", label: "Alle Kategorien" },
          ...categoryOptions,
        ]);

        setCategoryMapping(categoryMapping);
      } catch (error) {
        console.error("Fehler beim Abrufen der Kategorien:", error);
      }
    };
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="product-management-container">
      <div className="product-management-title">
        <h1>{product?.id ? "Produkt bearbeiten" : "Produkt erstellen"}</h1>
      </div>
      <div className="product-management-form-container">
        <div className="product-management-form-split">
          <div className="product-management-left-side">
            <div className="product-status-container">
              <h4>Status:</h4>
              <Switch
                value={isActive}
                onClick={handleToggle}
                labelChecked="aktiv"
                labelUnchecked="inaktiv"
              />
            </div>
            <div className="product-details-container">
              <h2>Details:</h2>
              <TextInputField
                label="Name"
                type="text"
                value={productData.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
              <TextInputField
                label="EAN"
                type="text"
                value={productData.ean}
                onChange={(e) => handleChange("ean", e.target.value)}
              />
              <TextInputField
                label="Artikelnummer"
                type="text"
                value={productData.articleNumber}
                onChange={(e) => handleChange("articleNumber", e.target.value)}
              />
              <div style={{ width: "322px" }}>
                <Select
                  value={productData.categoryId}
                  onChange={(e) => handleChange("categoryId", e.target.value)}
                  option={categoryOptions}
                />
              </div>

              <TextInputField
                label="Preis (brutto)"
                type="number"
                value={productData.priceGross}
                onChange={(e) => handleChange("priceGross", e.target.value)}
              />
              <TextInputField
                label="Preis (netto)"
                type="number"
                value={productData.priceNet}
                onChange={(e) => handleChange("priceNet", e.target.value)}
              />
              <TextInputField
                label="Steuer"
                type="number"
                value={productData.tax}
                onChange={(e) => handleChange("tax", e.target.value)}
              />
              <TextInputField
                label="Maßeinheit"
                type="text"
                value={productData.measureUnit}
                onChange={(e) => handleChange("measureUnit", e.target.value)}
              />
              <TextInputField
                label="Menge"
                type="number"
                value={productData.amount}
                onChange={(e) => handleChange("amount", e.target.value)}
              />
              <TextInputField
                label="Grundeinheit"
                type="text"
                value={productData.basePriceUnit}
                onChange={(e) => handleChange("basePriceUnit", e.target.value)}
              />
              <TextInputField
                label="Grundpreis pro Einheit"
                type="number"
                value={productData.basePricePerUnit}
                onChange={(e) =>
                  handleChange("basePricePerUnit", e.target.value)
                }
              />
              <TextInputField
                label="Grundpreis Menge"
                type="number"
                value={productData.basePriceAmount}
                onChange={(e) =>
                  handleChange("basePriceAmount", e.target.value)
                }
              />
              <div className="product-amount-container">
                <h2>Im Lager:</h2>
                <Counter
                  type="number"
                  value={
                    productData.productAmount === ""
                      ? 0
                      : Number(productData.productAmount)
                  }
                  onChange={(value) => handleChange("productAmount", value)}
                />
              </div>
            </div>
          </div>
          <div className="product-management-right-side">
            <h4>Bilder</h4>
            <div className="product-management-images">
              <div className="image-wrapper">
                <img
                  src={
                    productData.images?.product_1
                      ? productData.images.product_1
                      : LocaloraNewLogo
                  }
                  alt="Product Icon 1"
                  className="full-width-image"
                />
                <div className="upload-overlay">
                  <label htmlFor="productImageUpload1">
                    <img
                      src={UploadIcon}
                      alt="Upload Icon"
                      className="upload-icon"
                    />
                    <input
                      type="file"
                      id="productImageUpload1"
                      accept="image/*"
                      onChange={(e) => handleImageUpload("shopImageURL", e)}
                    />
                  </label>
                  {productData.images.product_1 && (
                    <button
                      onClick={() =>
                        deleteImage(productData.images.product_1, "product_1")
                      }
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      X
                    </button>
                  )}
                </div>
              </div>
              <div className="image-wrapper">
                <img
                  src={
                    productData.images?.product_2
                      ? productData.images.product_2
                      : LocaloraNewLogo
                  }
                  alt="Product Icon 2"
                  className="full-width-image"
                />
                <div className="upload-overlay">
                  <label htmlFor="productImageUpload2">
                    <img
                      src={UploadIcon}
                      alt="Upload Icon"
                      className="upload-icon"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <input
                      type="file"
                      id="productImageUpload2"
                      accept="image/*"
                      onChange={(e) => handleImageUpload("stageImageURL", e)}
                    />
                  </label>
                  {productData.images.product_2 && (
                    <button
                      onClick={() =>
                        deleteImage(productData.images.product_2, "product_2")
                      }
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        background: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      X
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="product-management-description">
              <TextArea
                label="Beschreibung"
                customClass="custom-input-size"
                rows={14}
                value={productData.description}
                onChange={(e) => handleChange("description", e.target.value)}
              />
            </div>
          </div>
        </div>
        <CustomButton
          label="Speichern"
          type="primary"
          variant="filled"
          onClick={handleSave}
        />
      </div>
    </div>
  );
}
