import {
  getUserShopsEndpoint,
  postCreateShopEndpoint,
  putUpdateShopEndpoint,
  putImageShopEndpoint,
  deleteImageShopEndpoint,
  replaceImageShopEndpoint
} from "./api_endpoints";

export class ShopService {
  async postCreateShop(shopData) {
    try {
      const response = await postCreateShopEndpoint(shopData);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async getUserShops() {
    try {
      const response = await getUserShopsEndpoint();
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async putUpdateShop(shopData) {
    try {
      const response = await putUpdateShopEndpoint(shopData);
      return response;
    } catch (error) {
      console.error("Fehler beim Aufrufen der Shops", error);
    }
  }

  async putImageShop(shopUUID, imageType, formData){
    try {
      const response = await putImageShopEndpoint(shopUUID, imageType, formData);
      return response;
    } catch (error) {
      console.error("Fehler beim Zugriff auf die Shop-Image-API");
    }
  }

  async deleteImageShop(/*shopUUID,*/ imagePath){
    try {
      const response = await deleteImageShopEndpoint(/*shopUUID,*/ imagePath);
      return response;
    } catch (error) {
      console.error("Fehler beim Zugriff auf die Shop-Image-API");
    }
  }

  async replaceImageShop(shopUUID, imagePath, formData){
    try {
      const response = await replaceImageShopEndpoint(shopUUID, imagePath, formData);
      return response;
    } catch (error) {
      console.error("Fehler beim Zugriff auf die Shop-Image-API");
    }
  }
}