import React from "react";
import "./Counter.css";

export default function Counter({ value, onChange }) {

  const increment = () => {
    // Value zu Integer
    value = parseInt(value);
    onChange(value + 1);
  };


  const decrement = () => {
    if (value === 0) return;
    value = parseInt(value);
    onChange(value - 1);
  };

  return (
    <div className="counter-container">
      <button className="counter-button" onClick={decrement}>-</button>
      <span className="counter-display">{value}</span>
      <button className="counter-button" onClick={increment}>+</button>
    </div>
  );
}
