import React from "react";
import LocaloraNewLogo from "../../../assets/Images/BannerShop.svg";
import "./ProductTableContent.css";

const ProductTableContent = ({
  onProductClick,
  sortedProducts,
  sortTable,
  categoryMapping,
}) => {
  return (
    <div className="product-table-container">
      <table>
        <thead>
          <tr>
            <th>Bild</th>
            <th onClick={() => sortTable("name")}>Name</th>
            <th onClick={() => sortTable("flagActive")}>Status</th>
            <th onClick={() => sortTable("articleNumber")}>Artikelnummer</th>
            <th onClick={() => sortTable("categoryId")}>Kategorie</th>
            <th onClick={() => sortTable("productAmount")}>Bestand</th>
            <th onClick={() => sortTable("measureUnit")}>Einheit</th>
            <th onClick={() => sortTable("basePriceAmount")}>
              Stückzahl pro Produkt
            </th>
            <th onClick={() => sortTable("priceGross")}>Preis Brutto</th>
            <th onClick={() => sortTable("priceNet")}>Preis Netto</th>
          </tr>
        </thead>
        <tbody>
          {sortedProducts.map((product) => (
            <tr key={product.id} onClick={() => onProductClick(product.id)}>
              <td data-label="Bild">
                <img src={product.images.product_1 || LocaloraNewLogo} alt=""style={{ width: "50px", height: "50px" }}
                />
              </td>
              <td data-label="Name">{product.name}</td>
              <td data-label="Status">
                {product.flagActive ? "Aktiv" : "Inaktiv"}
              </td>
              <td data-label="Artikelnummer">{product.articleNumber}</td>
              <td data-label="Kategorie">
                {categoryMapping[product.categoryId]}
              </td>
              <td data-label="Bestand">{product.productAmount}</td>
              <td data-label="Einheit">{product.measureUnit}</td>
              <td data-label="Stückzahl pro Produkt">
                {product.basePriceAmount}
              </td>
              <td data-label="Preis brutto">{product.priceGross}</td>
              <td data-label="Preis netto">{product.priceNet}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTableContent;
