import React, { useState, useContext } from "react";
import { firebaseApp } from "../../firebase/firebase";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import LocaLoraLogo from "../../assets/Images/LocaLoraLogo.svg";
import { UserContext } from "../../contexts/UserContext";
import { UserService } from "../../services/user_service";
import { ShopService } from "../../services/shop_service";
import { CookieService } from "../../services/cookie_service";

export default function LoginForm({ handleToggle }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useContext(UserContext);
  const { setUserData } = useContext(UserContext);
  const { setShopUUID } = useContext(UserContext);
  const userService = new UserService();
  const shopService = new ShopService();
  const cookieService = new CookieService();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async (user) => {
    navigate("/loading");
    const token = await user.getIdToken(true);
    await cookieService.postCreateCookie(token);

    try {
      const response = await userService.getUserByFirebaseId(user.uid);
      if (response) {
        if (response.firebaseAuthId === user.uid) {
          setUserData(response);
          if (response.newUser) {
            navigate("/onboarding/step1");
          } else {
            try {
              const shops = await shopService.getUserShops();
              setShopUUID(shops[0].id);
            } catch (error) {
              alert("Nutzershop konnt nicht geladen werden.");
              console.error("Fehler beim Laden der Nutzershops:", error);
            }
            navigate("/order");
          }
        }
      } else {
        alert("User wurde in der Datenbank nicht gefunden.");
      }
      setUser(user);
    } catch (error) {
      console.error("Fehler beim Login-Prozess:", error);
    }
  };

  const handleEmailLogin = (event) => {
    event.preventDefault();
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        handleLogin(user);
      })
      .catch((error) => {
        alert("Anmeldefehler: Email oder Passwort sind nicht korrekt.");
        console.error("Anmeldefehler:", error.message);
      });
  };

  const handleGoogleLogin = () => {
    const auth = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        handleLogin(user);
      })
      .catch((error) => {
        console.error("Anmeldefehler:", error.message);
      });
  };

  const handleRegistration = () => {
    handleToggle();
  };

  return (
    <div
      className="login-container"
      style={{
        height: "400px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <div className="logo-image">
        <img src={LocaLoraLogo} alt="Logo"></img>
      </div>
      <form
        className="login-form"
        onSubmit={(event) => handleEmailChange(event)}>
        <label htmlFor="email"></label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          style={{ textAlign: "center", height: "30px" }}
          required
        />
        <label htmlFor="password"></label>
        <input
          type="password"
          id="password"
          value={password}
          placeholder="Password"
          style={{ textAlign: "center", height: "30px" }}
          onChange={handlePasswordChange}
          required
        />
        <div className="buttonHolder">
          <button id="loginButton" onClick={handleEmailLogin} type="submit">
            Login
          </button>
          <button
            id="registerButton"
            onClick={handleRegistration}
            type="button">
            Register
          </button>
        </div>
      </form>
      <div className="container">
        <button className="gsi-material-button" onClick={handleGoogleLogin}>
          <div className="gsi-material-button-state"></div>
          <div className="gsi-material-button-content-wrapper">
            <div className="gsi-material-button-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span className="gsi-material-button-contents">
              Continue with Google
            </span>
            <span style={{ display: "none" }}>Continue with Google</span>
          </div>
        </button>
      </div>
    </div>
  );
}
