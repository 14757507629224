import React from "react";
import TextInputField from "../../../components/TextInputField/TextInputField";
import CustomerInfo from "../../../assets/Images/Onboarding/undraw_personal_info_re_ur1n.svg";

export default function OnboardingPageOne({ formData, onChange }) {
  const handleNameChange = (e) => {
    onChange("name", e.target.value);
  };

  return (
    <div className="OnboardingPageOne">
      <div className="title-container">
        <img src={CustomerInfo} alt=""></img>
      </div>
      <div>
        <h3>Wie lautet der Name Ihres Geschäfts?</h3>
      </div>
      <div className="input-container">
        <div
          style={{
            width: "300px",
            margin: "10px 0",
          }}>
          <TextInputField
            type="text"
            label="Geschäftsname"
            value={formData.name}
            onChange={handleNameChange}
            required
          />
        </div>
      </div>
    </div>
  );
}
