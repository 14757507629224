import React from "react";
import "./Switch.css";

// eslint-disable-next-line no-unused-vars
const SWITCH_WIDTH_PX = 72;
// eslint-disable-next-line no-unused-vars
const HANDLE_DIAMETER_PX = 30;
// eslint-disable-next-line no-unused-vars
const SWITCH_OFFSET_PX = 3;

export default function Switch({
  containerCheckedColor = "var(--switch-bg-color-checked)",
  containerUncheckedColor = "var(--switch-bg-color-unchecked)",
  handleCheckedColor = "var(--switch-handle-bg-color-checked)",
  handleUncheckedColor = "var(--switch-handle-bg-color-unchecked)",
  value,
  onClick,
  labelChecked = "geöffnet",
  labelUnchecked = "geschlossen",
}) {
  const label = value ? labelChecked : labelUnchecked;
  const containerClass = value
    ? "switch-content checked"
    : "switch-content unchecked";
  const handleClass = value
    ? "switch-handle checked"
    : "switch-handle unchecked";

  return (
    <div className="switch-container">
      <div
        className={containerClass}
        onClick={() => {
          onClick(!value);
        }}
        style={{
          backgroundColor: value
            ? containerCheckedColor
            : containerUncheckedColor,
        }}>
        <div
          className={handleClass}
          style={{
            backgroundColor: value ? handleCheckedColor : handleUncheckedColor,
          }}></div>
        <input
          id="switch"
          type="checkbox"
          value={value}
          onChange={(e) => {
            onClick(e.target.value);
          }}
          style={{ display: "none" }}
        />
      </div>
      <label htmlFor="switch">{label}</label>
    </div>
  );
}
